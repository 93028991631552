import { useState, useEffect, useRef } from 'react';
import { createContainer } from 'unstated-next';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import * as workspace from '@/common/api/workspace/workspace';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as schema from '@/bundles/schema/typescript/schema';
import * as model from '@/common/api/sub-workspace/detail/Edit';
import dayjs from 'dayjs';
import * as errorHandler from '@/common/utils/errorHandler';
import * as validator from '@/common/utils/validator';
import locale from '@/common/utils/locale';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as license from '@/common/api/licenses/license';
import environment from '@/common/constants/environment';
import * as errLocale from '@/common/utils/locale/error-locale';


export type PlanRow = {
    id: string;
    plan: schema.V1ObjectsLicensePlan;
    total: number;
    remaining: number;
    activationDate: string;
    expirationDate: string;
    status: string;
    isUpdate?: boolean;
    licenseKey: string | undefined;
};

export type AddRow = {
    id: string;
    plan: schema.V1ObjectsLicensePlan;
    usage: number;
    registerationDate: string;
};

const useAIocrManagementContainer = () => {
    const appContainer = AdminAppContainer.useContainer();
    const ui = useUI();
    const [values, setValues] = useState(model.New());
    const input1 = useRef('');
    const input2 = useRef('');
    const input3 = useRef('');
    const input4 = useRef('');
    const prefix = useRef('AON');
    const isButtonDisabled = useRef(true);
    const validationMessage1 = useRef('');
    const validationMessage2 = useRef('');
    const validationMessage3 = useRef('');
    const validationMessage4 = useRef('');
    const licenseInfo = useRef<schema.V1LicensesShowResponse | null>(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        ui.update(UI.Loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //入力値と押下判定とバリデーションメッセージの初期化
    const resetInputsAndButtonState = () => {
        input1.current = '';
        input2.current = '';
        input3.current = '';
        input4.current = '';
        isButtonDisabled.current = true;
        validationMessage1.current = '';
        validationMessage2.current = '';
        validationMessage3.current = '';
        validationMessage4.current = '';
    }

    const changePrefix = async () => {
        if (environment.tenant === 'otsuka') {
            prefix.current = 'AON';
        } else if (environment.tenant === 'ricoh') {
            prefix.current = 'ARN';
        }
    };

    /**
     * ライセンスキーの入力値が変更された際の処理
     * ボタンの活性非活性を切り替える
     */
    const changeButtonDisabled = () => {
        const allInputsFilled = input1.current.length === 4 && input2.current.length === 4 && input3.current.length === 4 && input4.current.length === 4;
        if (allInputsFilled) {
            isButtonDisabled.current = false;
        } else {
            isButtonDisabled.current = true;
        }
        return isButtonDisabled.current;
    };



    const findWorkspaceAllLicenses = async () => {
        try {
            ui.update(UI.Loading);
            if (appContainer.values.signinWorkspaceObject.id) {
                const result = await workspace.findWorkspaceLicenses(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
                return result;
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        } finally {
            ui.update(UI.Loaded);
        }
    };

    // 表示用に取得したデータを加工
    const convertPlanRows = (licensePlan: schema.V1ObjectsLicenseNew[]): PlanRow[] => {
        const rows: PlanRow[] = [];
        licensePlan.forEach((val) => {
            // 総枚数
            const totalLicenses = val.plan.volume + (val.addLicenses ? val.addLicenses.reduce((acc, cur) => acc + cur.plan.volume, 0) : 0);
            rows.push({
                id: val.id,
                plan: val.plan,
                total: totalLicenses,
                remaining: val.usage,
                activationDate: val.activationDate,
                expirationDate: val.expirationDate,
                status: val.status,
                isUpdate: val.isUpdate,
                licenseKey: val.licenseKey,
            });
        });
        return rows;
    };

    // 表示用に取得したデータを加工
    const convertAddRows = (addLicensePlan: schema.V1ObjectsLicenseAdd[]): AddRow[] => {
        const rows: AddRow[] = [];
        addLicensePlan.forEach((val) => {
            rows.push({
                id: val.id,
                plan: val.plan,
                usage: val.plan.volume,
                registerationDate: val.updatedAt,
            });
        });
        return rows;
    };

    const sortRows = (rows: PlanRow[]) => {
        const statusPriority: { [key in PlanRow['status']]: number } = {
            'active': 1,
            'inactive': 2,
            'expired': 4,
            'insufficient': 3
        };
        return rows.sort((a, b) => {
            const aPriority = statusPriority[a.status];
            const bPriority = statusPriority[b.status];

            if (aPriority < bPriority) return -1;
            if (aPriority > bPriority) return 1;
            if (aPriority === bPriority) {
                const expirationDiff = dayjs(a.expirationDate).diff(dayjs(b.expirationDate), 'day');
                if (expirationDiff !== 0) {
                    return expirationDiff;
                }
                return dayjs(a.activationDate).diff(dayjs(b.activationDate), 'millisecond');
            }
            return 0;
        });
    };

    const sortAddRows = (rows: AddRow[]) => {
        return rows.sort((a, b) => {
            return dayjs(a.registerationDate).diff(dayjs(b.registerationDate), 'day');
        });
    };

    const handleRegisterClick = async () => {
        try {
            ui.update(UI.Loading);
            const request: schema.V1LicensesActivateCreateRequest = {
                workspaceId: appContainer.values.signinWorkspaceObject.id!,
                licenseKey: [`${prefix.current}${input1.current}${input2.current}${input3.current}${input4.current}`],
            };
            await license.licenseActivate(request, appContainer.values.authorizationCode);
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.action.license),
                variant: Variants.success,
                autoHideDuration: 3000,
            });
            ui.update(UI.Loaded);
            resetInputsAndButtonState();
            setRefresh(prev => !prev);
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
            resetInputsAndButtonState();
            return;
        }
    }

    // ライセンスキーの情報取得
    // アクティベートは実施されない
    //　ライセンスのステータスが active の場合にエラーメッセージを表示
    //　ライセンスの有効期限が切れいてる場合にエラーメッセージを表示
    const getInacticeLicense = async () => {
        try {
            const licenseKey = `${prefix.current}${input1.current}${input2.current}${input3.current}${input4.current}`;
            const result = await license.getLicenseInfo(licenseKey, appContainer.values.authorizationCode);

            // ライセンスのステータスが active または有効期限切れの場合にエラーメッセージを表示
            const errorLicense = result.licenses.find((license: schema.V1ObjectsLicenseNew) => {
              return license.status === 'active' || dayjs(license.expirationDate).isBefore(dayjs());
            });
            
            if (errorLicense) {
                appContainer.updateMessage({
                    isOpen: true,
                    message: `${errLocale.translate(errLocale.keys.E11006).message}`,
                    variant: Variants.error,
                    resultCode: errLocale.keys.E11006,
                });
                ui.update(UI.Loaded);
                return false;
            }
            licenseInfo.current = result;
            return true
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            return false;
        }
    }

    // ライセンスキー
    const handleChangeLicenseKey = (v: string, inputRef: React.MutableRefObject<string>, nextInputId: string | null, validationMessageRef: React.MutableRefObject<string>, field: string) => {
        const upperCaseValue = v.toUpperCase();
        inputRef.current = upperCaseValue;
        setValues(prevValues => {
            const newValues = { ...prevValues, licenseKey: upperCaseValue, validateInit: { ...prevValues.validateInit, licenseKey: true } };
            const validationMessage = handleValidateLicenseKey(newValues, field);
            validationMessageRef.current = validationMessage;

            return newValues;
        });
        if (upperCaseValue.length === 4 && nextInputId) {
            const nextInput = document.getElementById(nextInputId) as HTMLInputElement;
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleValidateLicenseKey = (values: any, field: string) => {
        if (!values.validateInit.licenseKey) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg[field] === null ? '' : msg![field].toString();
        return dispMessage;
    };


    return {
        convertPlanRows,
        convertAddRows,
        findWorkspaceAllLicenses,
        sortRows,
        sortAddRows,
        input1,
        input2,
        input3,
        input4,
        prefix,
        isButtonDisabled,
        validationMessage1,
        validationMessage2,
        validationMessage3,
        validationMessage4,
        licenseInfo,
        handleChangeLicenseKey,
        handleValidateLicenseKey,
        resetInputsAndButtonState,
        handleRegisterClick,
        getInacticeLicense,
        changeButtonDisabled,
        changePrefix,
        values,
        refresh,
        ui,
    };
};

export const AIocrManagementContainer = createContainer(useAIocrManagementContainer);