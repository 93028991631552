import useTitle from '@/common/components/hooks/useTitle';
import locale from '@/common/utils/locale';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { AIocrManagementContainer } from './AIocrManagementContainer';
import AIocrManagement from './AIocrManagement';
import { default as React } from 'react';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { getSplitNewLineString } from '@/common/utils/webappUtil';

const styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            padding: `0px ${theme.spacing.unit * 3}px`,
        },
        root: {
            marginTop: theme.spacing.unit * 3,
        },
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{ id: string }> {}

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.pageTitle.admin.licenseManagement));
    const pageDescription = getSplitNewLineString(locale.t(locale.keys.pageTitle.adminHeaderDescription.aiOcrManagement)).join('\n');
    return (
        <>
            <SubHeader title={locale.t(locale.keys.aiOcrManagement.index)} pageDescription={pageDescription} />
            <div className={props.classes.mainDiv}>
                <div className={props.classes.root}>
                    <AIocrManagementContainer.Provider>
                        <AIocrManagement />
                    </AIocrManagementContainer.Provider>
                </div>
            </div>
        </>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));