import {
    Button,
    Card,
    CardContent,
    createStyles,
    Fab,
    Grid,
    IconButton,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Theme,
    Typography,
    withStyles,
    WithStyles,
    Avatar,
} from '@material-ui/core';
import { default as React, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import FormOrganization from '@/admin/components/common/FormOrganization';
import * as schema from '@/bundles/schema/typescript/schema';
import * as workspace from '@/common/api/workspace/workspace';
import LicenseConfirmDialogBase, {
    LicenseConfirmDialogBaseProps,
} from '@/common/components/Confirm/LicenseConfirmDialogBase';
import LicenseDialogBase, { LicenseDialogBaseProps } from '@/common/components/Confirm/LicenseDialogBase';
import { State as UI } from '@/common/components/hooks/useUI';
import ImageFileSelector from '@/common/components/ImageFileSelector';
import LanguageSelect from '@/common/components/LanguageSelect';
import { Variants } from '@/common/components/messages/CommonMessage';
import { AvailableFindLicenseWs, OTS_CUSTOMERS, RICOH_CUSTOMERS } from '@/common/constants/environment';
import * as errorHandler from '@/common/utils/errorHandler';
import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import * as uploader from '@/common/utils/uploader';
import { Block, Check, Delete, Edit, ErrorOutline, VisibilityOff, Visibility } from '@material-ui/icons';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { parse } from 'query-string';
import { AddRow, PlanRow, SubWorkspaceDetailContainer } from './SubWorkspaceDetailContainer';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const styles = (theme: Theme) =>
    createStyles({
        text: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        imageFileSelectorCardContent: {
            textAlign: 'center',
            paddingBottom: 0,
            height: '160px',
        },
        card: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        selectLanguage: {
            marginTop: '17px',
        },
        creatableSubWorkspace: {
            marginTop: '10px',
            alignItems: 'center',
            textAlign: 'left',
        },
        typoIcon: {
            color: '#43a047',
            fontSize: 20,
            margin: '5px 5px -5px 5px',
        },
        saveButton: {
            color: 'white',
            margin: theme.spacing.unit,
            minWidth: '240px',
        },
        content: {
            backgroundColor: theme.palette.background.paper,
            textAlign: 'left',
            padding: 0,
        },
        tabTags: {},
        tabGrid: {
            padding: '24px 16px 0 16px',
            backgroundColor: theme.palette.background.paper,
            minHeight: '500px',
            [theme.breakpoints.down('sm')]: {
                minHeight: '700px',
            },
        },
        button: {
            color: 'white',
            margin: 'auto',
            minWidth: '240px',
        },
        dialogButton: {
            color: 'white',
            margin: 'auto',
            minWidth: '40px',
            borderRadius: '50%', 
            padding: '10px',
        },
        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            gap: '0px',
            whiteSpace: 'nowrap',
            padding: '0 8px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
        tableCell: {
            padding: '0 24px',
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },
        oddRow: {
            background: '#f2f4f5',
            minHeight: 60,
        },
        evenRow: {
            background: 'inherit',
            minHeight: 60,
        },
        tableRoot: {
            width: '100%',
            marginTop: theme.spacing.unit * 5,
            overflowX: 'auto',
        },
        pencil: {
            color: 'orange',
        },
        active: {
            color: 'green',
        },
        inActive: {
            color: 'red',
        },
        standby: {
            color: 'black',
        },
        avatarIcon: {
            borderRadius: 0,
            width: 20,
            height: 20,
            opacity: 0.6,
        },
        visibilityIcon: {
            borderRadius: 0,
            width: 20,
            height: 20,
            opacity: 0.6,
        },
    });
interface Props extends RouteComponentProps<{ id: string }>, WithStyles<typeof styles> {}

const StyledTabs = withStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: '16px 0',
            minHeight: 32,
            display: 'inline-block',
            borderBottom: '2px solid #ff7300',
        },
        indicator: {
            display: 'none',
        },
    }),
)(Tabs);

const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 32,
            minWidth: 107,
            padding: 0,
            margin: '0 5px',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            '&:hover': {
                opacity: 1,
            },
            '&$selected': {
                color: 'white',
                backgroundColor: theme.palette.secondary.main,
            },
            '&$selected :active': {
                color: 'white',
            },
            '&:active': {
                color: theme.palette.secondary.main,
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab {...props} />);

interface StyledTabProps {
    label: string;
}

const initialDialogObject: LicenseDialogBaseProps = {
    callBack: () => {},
    onClose: () => {},
    isOpen: false,
    title: '',
    renderChildren: () => <></>,
    type: 'alert',
};

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const swdc = SubWorkspaceDetailContainer.useContainer();
    const { classes } = props;

    const [planRows, setPlanRows] = React.useState<PlanRow[]>([]);
    const [addRows, setAddRows] = React.useState<AddRow[]>([]);
    const [dialogObject, setDialogObject] = React.useState<LicenseDialogBaseProps>(initialDialogObject);
    const [confirmDialogObject, setConfirmDialogObject] =
        React.useState<LicenseConfirmDialogBaseProps>(initialDialogObject);
    const [isAddButtonDisabled, setIsAddButtonDisabled] = React.useState(true);
    const initialTabIndex = parseInt(sessionStorage.getItem('tabIndex') || '0', 10);
    const [tabIndex, setTabIndex] = React.useState(initialTabIndex);
    const [showLicenseKey, setShowLicenseKey] = React.useState<string | null>(null);
    let activeFound = false;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
        sessionStorage.setItem('tabIndex', newValue.toString());
    };

    const handleSave = () => {
        logger.debug('handleSave');
        if (
            !(
                appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS ||
                appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS
            ) &&
            appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace
        ) {
            return;
        }
        swdc.updateUIStatus(UI.Saving);

        (async () => {
            try {
                if (!swdc.values.workspace.id) {
                    return;
                }

                let workspaceLogoUrl = swdc.values.logoUrl;
                if (swdc.logoDataUri) {
                    const uploadRes = await uploader.Upload(
                        `${swdc.values.workspace.id}.png`,
                        schema.BlobType.WorkspaceLogo,
                        appContainer.values.authorizationCode,
                        swdc.logoDataUri,
                    );
                    workspaceLogoUrl = uploadRes.publicUrl;
                }
                const req: schema.V1WorkspaceUpdateRequest = {
                    displayId: swdc.values.workspaceId,
                    displayName: swdc.values.workspaceName,
                    enableSubWorkspace: swdc.values.enableSubWorkspace,
                    language: swdc.values.workspaceLang,
                    contactEmail: swdc.values.workspaceContactEmail,
                    logoUrl: workspaceLogoUrl,
                };
                logger.debug('organization', swdc.organization);
                if (swdc.organization) {
                    req.organizationDetail = {};
                    req.organizationDetail.name = swdc.organization.name ? swdc.organization.name : '';
                    req.organizationDetail.zipCode = swdc.organization.zipCode ? swdc.organization.zipCode : '';
                    req.organizationDetail.country = swdc.organization.country ? swdc.organization.country : '';
                    req.organizationDetail.address1 = swdc.organization.address1 ? swdc.organization.address1 : '';
                    req.organizationDetail.address2 = swdc.organization.address2 ? swdc.organization.address2 : '';
                    req.organizationDetail.phoneNumber = swdc.organization.phoneNumber
                        ? swdc.organization.phoneNumber
                        : '';
                    req.organizationDetail.customerId = swdc.organization.customerId
                        ? swdc.organization.customerId
                        : '';
                }
                const result = await workspace.updateWorkspace(
                    swdc.values.workspace.id,
                    appContainer.values.authorizationCode,
                    req,
                );
                logger.debug('updateWorkspace result', result);

                await swdc.loadWorkspace(result.displayId, appContainer.values.authorizationCode);
                props.history.replace(`/sub-workspace/detail/${result.displayId}`);
                swdc.openMessage(locale.t(locale.keys.action.saved), Variants.success);
                swdc.updateUIStatus(UI.Loaded);
            } catch (e) {
                swdc.updateUIStatus(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    useEffect(() => {
        if (
            tabIndex !== 2 ||
            !AvailableFindLicenseWs.includes(appContainer.values.signinWorkspaceObject.displayId) ||
            !swdc.values.workspace.verified
        ) {
            return;
        }
        const fetchData = async () => {
            try {
                const auth = appContainer.values.authorizationCode;
                const licensePlans = await swdc.findWorkspaceAllLicenses(auth);
                if (licensePlans) {
                    const convertedRows = swdc.convertPlanRows(licensePlans.licenses);
                    //追加ライセンスを取得
                    const allAddLicenses = licensePlans.licenses
                        .filter((license) => license.status !== 'expired') // expired を除外
                        .flatMap((license) => license.addLicenses || []);
                    const convertedAddRows = swdc.convertAddRows(allAddLicenses);
                    swdc.sortRows(convertedRows);
                    setPlanRows(convertedRows);
                    swdc.sortRowsByRegisterationDate(convertedAddRows);
                    setAddRows(convertedAddRows);
                }
            } catch (error) {
                errorHandler.handleApiError(appContainer, error);
            } finally {
                swdc.updateUIStatus(UI.Loaded);
            }
        };
        fetchData();
    }, [tabIndex, appContainer.values.signinWorkspaceObject.displayId, swdc.values.workspace.verified]);

    useEffect(() => {
        const queryParams = parse(window.location.search);
        if (queryParams.invite) {
            setTabIndex(0); // 新規ワークスペース招待から画面遷移したとき、プロファイルのタブを表示するように設定
        } else {
            const savedTabIndex = parseInt(sessionStorage.getItem('tabIndex') || '0', 10);
            setTabIndex(savedTabIndex);
        }
    }, []);

    useEffect(() => {
        if (swdc.values.workspace.active) {
            //アクティブ、枚数切れの新規ライセンスが存在しない場合、「+追加ライセンス登録」ボタンは無効。
            const hasActiveWithZeroRemaining = planRows.some((row) => row.status === 'active' || row.remaining === 0);
            setIsAddButtonDisabled(!hasActiveWithZeroRemaining);
        } else {
            setIsAddButtonDisabled(true);
        }
    }, [planRows]);

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const pasteData = event.clipboardData.getData('text').replace(/[\r\n]/g, '').trim();

        if (pasteData.length <= 4) {
            return;
        }
        event.preventDefault();
        
        let parts: string[] = [];
        if (pasteData.includes('-') || pasteData.includes('ー')) {
            // ハイフン区切りの場合
            parts = pasteData.split(/[-ー]/).map((part) => part.slice(0, 4));
        } else {
            // ハイフンなしの場合
            const prefixLength = 3;
            const dataWithoutPrefix = pasteData.slice(prefixLength); // プレフィックスを除外
            parts = dataWithoutPrefix.match(/.{1,4}/g) || []; // 4文字ごとに分割
        }

        const target = event.target as HTMLInputElement;
        const targetId = target.id;

        const valuesToPaste = parts.length > 4 ? parts.slice(1, 5) : parts.slice(0, 4);

        const currentValues = [
            swdc.input1.current || '',
            swdc.input2.current || '',
            swdc.input3.current || '',
            swdc.input4.current || '',
        ];

        // フォーカスされている TextField に応じて値を再配置
        if (valuesToPaste.length > 0) {
            let startIndex = 0;

            if (targetId === 'input1') {
                startIndex = 0;
            } else if (targetId === 'input2') {
                startIndex = 1;
            } else if (targetId === 'input3') {
                startIndex = 2;
            } else if (targetId === 'input4') {
                startIndex = 3;
            }

            // ペーストされた値を現在の値に適用
            const newValues = [...currentValues];
            for (let i = 0; i < valuesToPaste.length && startIndex + i < newValues.length; i++) {
                newValues[startIndex + i] = valuesToPaste[i];
            }

            swdc.handleChangeLicenseKey(newValues[0], swdc.input1, 'input2', swdc.validationMessage1, 'licenseKey');
            swdc.handleChangeLicenseKey(newValues[1], swdc.input2, 'input3', swdc.validationMessage2, 'licenseKey');
            swdc.handleChangeLicenseKey(newValues[2], swdc.input3, 'input4', swdc.validationMessage3, 'licenseKey');
            swdc.handleChangeLicenseKey(newValues[3], swdc.input4, null, swdc.validationMessage4, 'licenseKey');
        }
    };
    
    // ライセンスキー入力ダイアログ
    const handleClick = () => {
        setDialogObject({
            callBack: () => {
                setDialogObject(initialDialogObject);
            },
            onClose: () => {
                setDialogObject(initialDialogObject);
                swdc.resetInputsAndButtonState();
            },
            isOpen: true,
            title: locale.t(locale.keys.licensesManagement.register.button),
            renderChildren: () => {
                return (
                    <>
                        <Typography style={{ fontSize: '0.875rem', margin: 10 }}>
                            {locale.t(locale.keys.licensesManagement.register.key)}
                        </Typography>
                        <div style={{ fontSize: '25px' }}>
                            {swdc.prefix.current} ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input1"
                                    type="text"
                                    value={swdc.input1.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        swdc.handleChangeLicenseKey(
                                            event.target.value,
                                            swdc.input1,
                                            'input2',
                                            swdc.validationMessage1,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={swdc.validationMessage1.current !== ''}
                                    helperText={swdc.validationMessage1.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                            ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input2"
                                    type="text"
                                    value={swdc.input2.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        swdc.handleChangeLicenseKey(
                                            event.target.value,
                                            swdc.input2,
                                            'input3',
                                            swdc.validationMessage2,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={swdc.validationMessage2.current !== ''}
                                    helperText={swdc.validationMessage2.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                            ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input3"
                                    type="text"
                                    value={swdc.input3.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        swdc.handleChangeLicenseKey(
                                            event.target.value,
                                            swdc.input3,
                                            'input4',
                                            swdc.validationMessage3,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={swdc.validationMessage3.current !== ''}
                                    helperText={swdc.validationMessage3.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                            ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input4"
                                    type="text"
                                    value={swdc.input4.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        swdc.handleChangeLicenseKey(
                                            event.target.value,
                                            swdc.input4,
                                            null,
                                            swdc.validationMessage4,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={swdc.validationMessage4.current !== ''}
                                    helperText={swdc.validationMessage4.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                        </div>
                        <Typography style={{ fontSize: '0.75rem', margin: 30, marginTop: 40 }}>
                            {locale.t(locale.keys.licensesManagement.register.description2)}
                        </Typography>
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={async () => {
                                    // 事前にライセンスキーの確認
                                    const result = await swdc.getInacticeLicense();
                                    if (result === true) {
                                        handleConfirmClick(
                                            `${swdc.prefix.current} ー ${swdc.input1.current} ー ${swdc.input2.current} ー ${swdc.input3.current} ー ${swdc.input4.current}`,
                                        );
                                        setDialogObject(initialDialogObject);
                                    }
                                }}
                                style={{ borderRadius: '50px', padding: '10px 20px' }}
                                disabled={swdc.changeButtonDisabled()}
                            >
                                <div style={{ color: 'white' }}>
                                    {locale.t(locale.keys.licensesManagement.register.button)}
                                </div>
                            </Button>
                        </div>
                    </>
                );
            },
            type: 'confirm',
        });
    };

    const formatLicenseKey = (key: string): string => {
        return key.replace(/(.{3})(.{4})(.{4})(.{4})(.{4})/, '$1-$2-$3-$4-$5');
    };

    // ライセンス登録の確認ダイアログ
    const handleConfirmClick = async (licenseKey: string) => {
        const expirationDate = dayjs()
            .add(10, 'day')
            .startOf('month')
            .add(1, 'month')
            .startOf('month')
            .add(1, 'year')
            .subtract(1, 'day')
            .format('YYYY/MM/DD');
        setConfirmDialogObject({
            callBack: () => {
                setConfirmDialogObject(initialDialogObject);
                swdc.handleRegisterClick();
            },
            onClose: () => {
                setConfirmDialogObject(initialDialogObject);
                handleClick();
                swdc.resetInputsAndButtonState();
            },
            isOpen: true,
            title: locale.t(locale.keys.licensesManagement.register.button),
            renderChildren: () => {
                return (
                    <>
                        <Typography style={{ fontSize: '0.875rem', margin: 10 }}>
                            {locale.t(locale.keys.licensesManagement.register.key)}
                        </Typography>
                        <Typography style={{ fontSize: '25px', margin: 10 }}>{licenseKey}</Typography>
                        <Typography style={{ fontSize: '20px', margin: 30 }}>
                            {locale.t(locale.keys.licensesManagement.register.confirm.description1)}
                        </Typography>
                        <Typography style={{ fontSize: '15px', margin: 10 }}>
                            {`${locale.t(locale.keys.licensesManagement.register.confirm.plan)}: ${swdc.licenseInfo.current ? swdc.licenseInfo.current.licenses[0].plan.name : ''}`}
                        </Typography>
                        <Typography style={{ fontSize: '15px', margin: 10 }}>
                            {`${locale.t(locale.keys.licensesManagement.register.confirm.expirationDate)}: ${expirationDate}`}
                        </Typography>
                        <Typography style={{ fontSize: '15px', margin: 10 }}>
                            {`${locale.t(locale.keys.licensesManagement.register.confirm.usage)}: ${swdc.licenseInfo && swdc.licenseInfo.current ? swdc.licenseInfo.current.licenses[0].plan.volume : ''}${locale.t(locale.keys.licensesManagement.register.confirm.sheets)}`}
                        </Typography>
                    </>
                );
            },
            type: 'confirm',
        });
    };

    /**
     * ライセンス編集の編集確認ダイアログを表示する前の確認
     * @param licenseKey 元のライセンスキー。入力はswdcのinput1-4を結合したものから取得可能。
     */
    const handleEditPreCheck = async (licenseKey: string, callback: (licenseKey: string) => Promise<void>) => {
        // 入力の確認
        if (
            licenseKey !==
            `${swdc.prefix.current}${swdc.input1.current}${swdc.input2.current}${swdc.input3.current}${swdc.input4.current}`
        ) {
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.licensesManagement.register.confirm.description5),
                variant: Variants.error,
            });
            return;
        }
        // 事前にライセンスキーの確認
        const result = await swdc.getLicense();
        if (result === true) {
            callback(licenseKey);
            setDialogObject(initialDialogObject);
        }
    };

    const handleEditClick = (licenseKey: string = '') => {
        //編集ボタンからのダイアログ
        setDialogObject({
            callBack: () => {
                setDialogObject(initialDialogObject);
            },
            onClose: () => {
                setDialogObject(initialDialogObject);
                swdc.resetInputsAndButtonState();
            },
            isOpen: true,
            title: locale.t(locale.keys.licensesManagement.register.update),
            renderChildren: () => {
                return (
                    <>
                        <Typography style={{ fontSize: '0.875rem', margin: 10 }}>
                            {locale.t(locale.keys.licensesManagement.register.key)}
                        </Typography>
                        <div style={{ fontSize: '25px' }}>
                            {swdc.prefix.current} ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input1"
                                    type="text"
                                    value={swdc.input1.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        swdc.handleChangeLicenseKey(
                                            event.target.value,
                                            swdc.input1,
                                            'input2',
                                            swdc.validationMessage1,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={swdc.validationMessage1.current !== ''}
                                    helperText={swdc.validationMessage1.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                            ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input2"
                                    type="text"
                                    value={swdc.input2.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        swdc.handleChangeLicenseKey(
                                            event.target.value,
                                            swdc.input2,
                                            'input3',
                                            swdc.validationMessage2,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={swdc.validationMessage2.current !== ''}
                                    helperText={swdc.validationMessage2.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                            ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input3"
                                    type="text"
                                    value={swdc.input3.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        swdc.handleChangeLicenseKey(
                                            event.target.value,
                                            swdc.input3,
                                            'input4',
                                            swdc.validationMessage3,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={swdc.validationMessage3.current !== ''}
                                    helperText={swdc.validationMessage3.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                            ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input4"
                                    type="text"
                                    value={swdc.input4.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        swdc.handleChangeLicenseKey(
                                            event.target.value,
                                            swdc.input4,
                                            null,
                                            swdc.validationMessage4,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={swdc.validationMessage4.current !== ''}
                                    helperText={swdc.validationMessage4.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                        </div>
                        <Typography style={{ fontSize: '0.75rem', margin: '15px', marginTop: '40px' }}>
                            {locale.t(locale.keys.licensesManagement.Edit.description)}
                        </Typography>
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={() => {
                                    // 入力の確認
                                    handleEditPreCheck(licenseKey, handleEditConfirmClick);
                                }}
                                style={{ borderRadius: '40px', padding: '7px 15px' }}
                                disabled={swdc.values.workspace.active ? swdc.changeButtonDisabled() : true}
                            >
                                <div style={{ color: 'white' }}>
                                    <Check style={{ width: 20, height: 20, marginInlineEnd: 4 }} />
                                    {locale.t(locale.keys.licensesManagement.Edit.button.update)}
                                </div>
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={() => {
                                    // 入力の確認
                                    handleEditPreCheck(licenseKey, handleDeleteClick);
                                }}
                                style={{ borderRadius: '50px', padding: '7px 17px' }}
                                disabled={swdc.changeButtonDisabled()}
                            >
                                <Delete style={{ width: 20, height: 20, marginInlineEnd: 4 }} />
                                <div style={{ color: 'white' }}>
                                    {locale.t(locale.keys.licensesManagement.Edit.button.delete)}
                                </div>
                            </Button>
                        </div>
                        <div style={{ position: 'absolute', bottom: 0, right: 0, margin: '10px', marginTop: '30px' }}>
                            <Typography style={{ fontSize: '0.875rem' }}>{formatLicenseKey(licenseKey)}</Typography>
                        </div>
                    </>
                );
            },
            type: 'confirm',
        });
    };

    const handleEditConfirmClick = async (licenseKey: string) => {
        const expirationDate = dayjs()
            .add(10, 'day')
            .add(1, 'month')
            .startOf('month')
            .add(1, 'year')
            .subtract(1, 'day')
            .format('YYYY/MM/DD');
        setConfirmDialogObject({
            callBack: async () => {
                setConfirmDialogObject(initialDialogObject);
                if (swdc.licenseInfo.current) {
                    await swdc.handleUpdateClick(swdc.licenseInfo.current.licenses[0].id);
                }
            },
            onClose: () => {
                setConfirmDialogObject(initialDialogObject);
                // 閉じたときに入力画面を再表示
                handleEditClick(licenseKey);
                swdc.resetInputsAndButtonState();
            },
            isOpen: true,
            title: locale.t(locale.keys.licensesManagement.register.update),
            renderChildren: () => {
                return (
                    <>
                        <Typography style={{ fontSize: '0.875rem', margin: 10 }}>
                            {locale.t(locale.keys.licensesManagement.register.key)}
                        </Typography>
                        <Typography style={{ fontSize: '25px', margin: 10 }}>{formatLicenseKey(licenseKey)}</Typography>
                        <Typography style={{ fontSize: '20px', margin: 30 }}>
                            {locale.t(locale.keys.licensesManagement.register.confirm.description2)}
                        </Typography>
                        <Typography style={{ fontSize: '15px', margin: 10 }}>
                            {`${locale.t(locale.keys.licensesManagement.register.confirm.plan)}: ${swdc.licenseInfo.current ? swdc.licenseInfo.current.licenses[0].plan.name : ''}`}
                        </Typography>
                        <Typography style={{ fontSize: '15px', margin: 10 }}>
                            {`${locale.t(locale.keys.licensesManagement.register.confirm.expirationDate)}: ${expirationDate}`}
                        </Typography>
                        <Typography style={{ fontSize: '15px', margin: 10 }}>
                            {`${locale.t(locale.keys.licensesManagement.register.confirm.usage)}: ${swdc.licenseInfo && swdc.licenseInfo.current ? swdc.licenseInfo.current.licenses[0].plan.volume : ''}${locale.t(locale.keys.licensesManagement.register.confirm.sheets)}`}
                        </Typography>
                    </>
                );
            },
            type: 'confirm',
        });
    };

    /**
     * ライセンス削除確認ダイアログ
     * @param licenseKey
     */
    const handleDeleteClick = async (licenseKey: string) => {
        // 有効期限が切れていない場合は削除ボタンを無効化
        const expired = swdc.licenseInfo.current
            ? dayjs().isSameOrAfter(swdc.licenseInfo.current.licenses[0].expirationDate)
            : false;

        setConfirmDialogObject({
            callBack: async () => {
                setConfirmDialogObject(initialDialogObject);
                if (swdc.licenseInfo.current && expired) {
                    await swdc.handleDeleteClick(swdc.licenseInfo.current.licenses[0].id);
                }
            },
            onClose: () => {
                setConfirmDialogObject(initialDialogObject);
                // 閉じたときに入力画面を再表示
                handleEditClick(licenseKey);
                swdc.resetInputsAndButtonState();
            },
            isOpen: true,
            title: locale.t(locale.keys.licensesManagement.Edit.button.delete),
            renderChildren: () => {
                return (
                    <>
                        <Typography style={{ fontSize: '0.875rem', margin: 10 }}>
                            {locale.t(locale.keys.licensesManagement.register.key)}
                        </Typography>
                        <Typography style={{ fontSize: '25px', margin: 10 }}>{formatLicenseKey(licenseKey)}</Typography>
                        {expired ? (
                            // 有効期限が切れている場合
                            <div>
                                <Typography style={{ fontSize: '20px', margin: '30 30 0 30' }}>
                                    <span style={{ color: 'red' }}>{locale.t(locale.keys.action.delete)}</span>
                                    {locale.t(locale.keys.licensesManagement.register.confirm.description3)}
                                </Typography>
                                <Typography style={{ fontSize: '16px', color: 'red' }}>
                                    {locale.t(locale.keys.licensesManagement.register.confirm.description6)}
                                </Typography>
                            </div>
                        ) : (
                            // 有効期限が切れていない場合
                            <Typography style={{ fontSize: '20px', margin: 30 }}>
                                {locale.t(locale.keys.licensesManagement.register.confirm.description4)}
                            </Typography>
                        )}
                    </>
                );
            },
            type: 'confirm',
            disabled: !expired,
        });
    };

    const isWithinThreeMonths = (expirationDate: string): boolean => {
        const today = dayjs().startOf('day');
        const expiration = dayjs(expirationDate).subtract(1, 'day').startOf('day');
        const threeMonthsBeforeExpiration = expiration.subtract(3, 'month');

        return threeMonthsBeforeExpiration.isSameOrBefore(today);
    };

    const handleCopy = (text: string) => {
        const formattedText = formatLicenseKey(text);
        navigator.clipboard.writeText(formattedText).then(() => {
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.orderOCRLicenseDetail.button.description1),
                variant: Variants.success,
            });
        });
    };

    //ライセンスキー表示ダイアログ
    const handleLicenseDialogOpen = (licenseKey: string) => {
        setDialogObject({
            callBack: () => setDialogObject(initialDialogObject),
            onClose: () => {
                setDialogObject(initialDialogObject);
                setShowLicenseKey(null);
            },
            isOpen: true,
            title: locale.t(locale.keys.licensesManagement.table.licenseKey),
            type: "alert",
            renderChildren: () => (
                <>
                 <div style={{ textAlign: 'center', margin: '5px 10px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <div style={{marginTop: '20px', marginRight: '20px'}}>
                    {showLicenseKey === licenseKey
                            ?  formatLicenseKey(licenseKey) // フォーマット済みのライセンスキーを表示
                            :  '****-****-****-****-****' // マスクされたライセンスキーを表示
                        }                    
                    </div>
                    <Button
                        variant="contained"
                        className={classes.dialogButton}
                        onClick={() => {
                            setDialogObject((prevDialogObject) => ({
                                ...prevDialogObject,
                                renderChildren: () => (
                                    <>
                                        <div style={{ textAlign: 'center', margin: '10px 0', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <Typography>{formatLicenseKey(licenseKey)}</Typography>
                                            <Button
                                                variant="contained"
                                                className={classes.dialogButton}
                                                onClick={() => {
                                                    handleLicenseDialogOpen(licenseKey);
                                                }}
                                                style={{ borderRadius: '40px', backgroundColor: 'transparent',marginLeft: '10px' }}
                                            >
                                                <VisibilityOff style={{ color: 'gray' }} />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                className={classes.dialogButton}
                                                onClick={() => {
                                                    handleCopy(licenseKey);
                                                }}
                                                style={{ borderRadius: '50px', backgroundColor: 'transparent' }}
                                            >
                                                <Avatar className={classes.visibilityIcon} src="/assets/images/logo/copy.svg" />
                                            </Button>
                                        </div>
                                    </>
                                ),
                            }));
                        }}
                        style={{ borderRadius: '40px', backgroundColor: 'transparent', marginTop: '10px' }}
                    >
                        <Visibility style={{ color: 'gray' }} />
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.dialogButton}
                        onClick={() => {
                            handleCopy(licenseKey);
                        }}
                        style={{ borderRadius: '50px', backgroundColor: 'transparent', marginTop: '10px' }}
                    >
                        <Avatar className={classes.visibilityIcon} src="/assets/images/logo/copy.svg" />
                    </Button>
                </div>
            </>
            ),
        });
    };

    return (
        <>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <StyledTabs value={tabIndex} onChange={handleChange} className={classes.tabTags}>
                        <StyledTab label={locale.t(locale.keys.workspaceSetting.index)} />
                        <StyledTab label={locale.t(locale.keys.organizationInfo.index)} />
                        {AvailableFindLicenseWs.includes(appContainer.values.signinWorkspaceObject.displayId) &&
                            swdc.values.workspace.verified && (
                                <StyledTab label={locale.t(locale.keys.licensesManagement.index)} />
                            )}
                    </StyledTabs>
                    {tabIndex === 0 && (
                        // プロファイル
                        <Grid container spacing={16} className={classes.tabGrid}>
                            <Grid item xs={12} sm={5} style={{ paddingBottom: 0 }}>
                                <Typography align="left" color="textSecondary">
                                    {locale.t(locale.keys.subWorkspaceDetail.settingPanel.logo.index)}
                                </Typography>
                                <CardContent className={classes.imageFileSelectorCardContent}>
                                    <div>
                                        <ImageFileSelector
                                            seed={swdc.values.workspace.id}
                                            uploadWidth={512}
                                            uploadHeight={512}
                                            mobileWidth={160}
                                            mobileHeight={160}
                                            pcWidth={160}
                                            pcHeight={160}
                                            defaultUrl={swdc.values.logoUrl}
                                            logoDataUri={swdc.logoDataUri}
                                            isAvatar={true}
                                            editable={
                                                appContainer.values.signinWorkspaceObject.id ===
                                                swdc.values.workspace.parentWorkspace
                                            }
                                            onLoaded={(datauri) => {
                                                swdc.handleChangeLogoDataUri(datauri);
                                            }}
                                            dependAppContainer={AdminAppContainer.useContainer}
                                        />
                                    </div>
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} sm={7} style={{ paddingBottom: 0 }}>
                                <CardContent style={{ paddingBottom: 0 }}>
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.subWorkspaceDetail.settingPanel.workspaceId.index)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={swdc.values.workspaceId}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                                            >,
                                        ) => swdc.handleChangeWorkspaceId(event.target.value)}
                                        error={swdc.handleValidateWorkspaceId() !== ''}
                                        helperText={swdc.handleValidateWorkspaceId()}
                                        disabled={
                                            !(
                                                appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS ||
                                                appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS
                                            ) &&
                                            appContainer.values.signinWorkspaceObject.id !==
                                                swdc.values.workspace.parentWorkspace
                                        }
                                    />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(
                                            locale.keys.subWorkspaceDetail.settingPanel.workspaceDisplayName.index,
                                        )}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={swdc.values.workspaceName}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                                            >,
                                        ) => swdc.handleChangeWorkspaceName(event.target.value)}
                                        error={swdc.handleValidateWorkspaceName() !== ''}
                                        helperText={swdc.handleValidateWorkspaceName()}
                                        disabled={
                                            !(
                                                appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS ||
                                                appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS
                                            ) &&
                                            appContainer.values.signinWorkspaceObject.id !==
                                                swdc.values.workspace.parentWorkspace
                                        }
                                    />

                                    <div className={classes.selectLanguage}>
                                        <LanguageSelect
                                            value={swdc.values.workspaceLang}
                                            label={locale.t(locale.keys.newWorkspaceSetting.workspaceLanguage)}
                                            handleChange={(event) => swdc.handleChangeWorkspaceLang(event.target.value)}
                                            handleValidate={() => swdc.handleValidateWorkspaceLang()}
                                            disabled={
                                                !(
                                                    appContainer.values.signinWorkspaceObject.displayId ===
                                                        OTS_CUSTOMERS ||
                                                    appContainer.values.signinWorkspaceObject.displayId ===
                                                        RICOH_CUSTOMERS
                                                ) &&
                                                appContainer.values.signinWorkspaceObject.id !==
                                                    swdc.values.workspace.parentWorkspace
                                            }
                                        />
                                    </div>

                                    {/* {swdc.values.enableSubWorkspace ? (
                                        <Typography className={classes.creatableSubWorkspace}>
                                            <CheckCircle className={classes.typoIcon} />
                                            {locale.t(locale.keys.subWorkspaceDetail.settingPanel.enableSubWorkspace.index)}
                                        </Typography>
                                    ) : (
                                        <br />
                                    )} */}

                                    <br />
                                    <br />
                                    <TextField
                                        className={classes.text}
                                        label={locale.t(locale.keys.subWorkspaceDetail.settingPanel.contactEmail.index)}
                                        placeholder=""
                                        margin="normal"
                                        variant="filled"
                                        value={swdc.values.workspaceContactEmail}
                                        inputProps={{ style: { height: '100%' } }}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                                            >,
                                        ) => swdc.handleChangeContactEmail(event.target.value)}
                                        error={swdc.handleValidateContactEmail() !== ''}
                                        helperText={swdc.handleValidateContactEmail()}
                                        disabled={
                                            !(
                                                appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS ||
                                                appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS
                                            ) &&
                                            appContainer.values.signinWorkspaceObject.id !==
                                                swdc.values.workspace.parentWorkspace
                                        }
                                    />
                                </CardContent>
                            </Grid>
                        </Grid>
                    )}
                    {tabIndex === 1 && (
                        // 組織情報
                        <FormOrganization
                            organization={swdc.organization}
                            setOrganization={swdc.setOrganization}
                            disabled={
                                !(
                                    appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS ||
                                    appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS
                                ) &&
                                appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace
                            }
                        />
                    )}
                    {tabIndex === 2 &&
                        AvailableFindLicenseWs.includes(appContainer.values.signinWorkspaceObject.displayId) &&
                        swdc.values.workspace.verified && (
                            // ライセンス一覧
                            <div style={{ marginLeft: '20px' }}>
                                {planRows.length === 0 && ( //ライセンス初期画面
                                    <>
                                        <h4 style={{ marginBottom: '5px' }}>
                                            {locale.t(locale.keys.licensesManagement.title.index)}
                                        </h4>
                                        <h5>{locale.t(locale.keys.licensesManagement.initial.description)}</h5>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '80px',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.button}
                                                onClick={() => {
                                                    swdc.changePrefix('new');
                                                    handleClick();
                                                }}
                                                style={{ borderRadius: '50px', padding: '10px 20px' }}
                                                disabled={swdc.values.workspace.active ? false : true}
                                            >
                                                {locale.t(locale.keys.licensesManagement.initial.button)}
                                            </Button>
                                        </div>
                                    </>
                                )}
                                {planRows.length != 0 && ( //ライセンス管理画面
                                    <>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <h4 style={{ marginBottom: '5px' }}>
                                                    {locale.t(locale.keys.licensesManagement.title.index)}
                                                </h4>
                                                <h5>{locale.t(locale.keys.licensesManagement.description)}</h5>
                                            </Grid>
                                            <Grid item style={{ marginLeft: 'auto', marginRight: '30px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    className={classes.button}
                                                    onClick={() => {
                                                        swdc.changePrefix('new');
                                                        handleClick();
                                                    }}
                                                    style={{ borderRadius: '50px', padding: '10px 20px' }}
                                                    disabled={swdc.values.workspace.active ? false : true}
                                                >
                                                    {locale.t(locale.keys.licensesManagement.button.new)}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Paper
                                            className={props.classes.tableRoot}
                                            style={{ marginTop: '5px', width: '95%' }}
                                        >
                                            <Table>
                                                <TableHead>
                                                    <TableRow className={props.classes.headerFont}>
                                                        <TableCell className={props.classes.tableCell} align="left">
                                                            <div className={props.classes.gridInsideHeaderCell}>
                                                                <p className={props.classes.gridInsideHeaderCellText}>
                                                                    {locale.t(
                                                                        locale.keys.licensesManagement.table.plan,
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={props.classes.tableCell}>
                                                            <div className={props.classes.gridInsideHeaderCell}>
                                                                <p className={props.classes.gridInsideHeaderCellText}>
                                                                    {locale.t(
                                                                        locale.keys.licensesManagement.table.total,
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={props.classes.tableCell}>
                                                            <div className={props.classes.gridInsideHeaderCell}>
                                                                <p className={props.classes.gridInsideHeaderCellText}>
                                                                    {locale.t(
                                                                        locale.keys.licensesManagement.table.remaining,
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={props.classes.tableCell}>
                                                            <div className={props.classes.gridInsideHeaderCell}>
                                                                <p className={props.classes.gridInsideHeaderCellText}>
                                                                    {locale.t(
                                                                        locale.keys.licensesManagement.table.startDate,
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={props.classes.tableCell}>
                                                            <div className={props.classes.gridInsideHeaderCell}>
                                                                <p className={props.classes.gridInsideHeaderCellText}>
                                                                    {locale.t(
                                                                        locale.keys.licensesManagement.table
                                                                            .expirationDate,
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </TableCell>
                                                    <TableCell className={props.classes.tableCell} align="center">{locale.t(locale.keys.licensesManagement.table.status.index)}</TableCell>
                                                    <TableCell className={props.classes.tableCell} align="center" style={{ wordBreak: 'keep-all' }}>{locale.t(locale.keys.licensesManagement.table.licenseKey)}</TableCell>
                                                    <TableCell className={props.classes.tableCell} align="center">{locale.t(locale.keys.licensesManagement.table.edit)}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {planRows.map((row, index) => (
                                                        <TableRow
                                                            key={row.id}
                                                            className={
                                                                index % 2 ? props.classes.evenRow : props.classes.oddRow
                                                            }
                                                        >
                                                            <TableCell className={props.classes.tableCell} style={{ whiteSpace: 'nowrap' }}>
                                                                {row.plan.name}
                                                            </TableCell>
                                                            <TableCell className={props.classes.tableCell} align="left">
                                                                {row.total}
                                                            </TableCell>
                                                            <TableCell
                                                                className={props.classes.tableCell}
                                                                align="left"
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    color:
                                                                        row.remaining <= 0
                                                                            ? 'red'
                                                                            : row.remaining <= 100
                                                                              ? 'orange'
                                                                              : 'inherit',
                                                                }}
                                                            >
                                                                {row.remaining}
                                                            </TableCell>
                                                            <TableCell
                                                                className={props.classes.tableCell} align="left"
                                                            >
                                                                {dayjs(row.activationDate).format('YYYY/MM/DD')}
                                                            </TableCell>
                                                            <TableCell
                                                                className={props.classes.tableCell}
                                                                align="center"
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    color:
                                                                        dayjs(row.expirationDate)
                                                                            .subtract(1, 'day')
                                                                            .startOf('day')
                                                                            .diff(dayjs().startOf('day'), 'day') < 0
                                                                            ? 'red'
                                                                            : dayjs(row.expirationDate)
                                                                                    .subtract(1, 'day')
                                                                                    .startOf('day')
                                                                                    .diff(
                                                                                        dayjs().startOf('day'),
                                                                                        'day',
                                                                                    ) < 30
                                                                              ? 'orange'
                                                                              : 'inherit',
                                                                }}
                                                            >
                                                                {/** 月末を期限日として表示する */}
                                                                {dayjs(row.expirationDate)
                                                                    .subtract(1, 'day')
                                                                    .format('YYYY/MM/DD')}
                                                            </TableCell>
                                                            {row.status === 'expired' ? (
                                                                <TableCell
                                                                    className={`${props.classes.tableCell} ${classes.inActive}`} 
                                                                    align="center"
                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                >
                                                                    <Block style={{ marginBottom: -5 }} />
                                                                    {locale.t(
                                                                        locale.keys.licensesManagement.table.status
                                                                            .expired,
                                                                    )}
                                                                </TableCell>
                                                            ) : row.status === 'insufficient' ? (
                                                                <TableCell
                                                                    className={`${props.classes.tableCell} ${classes.inActive}`} 
                                                                    align="center"
                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                >
                                                                    <Block style={{ marginBottom: -5 }} />
                                                                    {locale.t(
                                                                        locale.keys.licensesManagement.table.status
                                                                            .soldout,
                                                                    )}
                                                                </TableCell>
                                                            ) : row.status === 'active' && !activeFound ? (
                                                                ((activeFound = true),
                                                                (
                                                                    <TableCell
                                                                        className={`${props.classes.tableCell} ${classes.active}`}
                                                                        align="center"
                                                                        style={{
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >
                                                                        <Check style={{ marginBottom: -5 }} />
                                                                        {locale.t(
                                                                            locale.keys.licensesManagement.table.status
                                                                                .active,
                                                                        )}
                                                                    </TableCell>
                                                                ))
                                                            ) : (
                                                                <TableCell
                                                                    className={`${props.classes.tableCell} ${classes.standby}`} 
                                                                    align="center"
                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                >
                                                                    {locale.t(
                                                                        locale.keys.licensesManagement.table.status
                                                                            .standby,
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                            <TableCell className={props.classes.tableCell} style={{ whiteSpace: 'nowrap' }} align="center">
                                                                <IconButton onClick={() => row.licenseKey && handleLicenseDialogOpen(row.licenseKey)}>
                                                                    <ErrorOutline color='secondary' />
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                style={{ whiteSpace: 'nowrap', padding: '0 16px' }}
                                                            >
                                                                {isWithinThreeMonths(row.expirationDate) &&
                                                                    !row.isUpdate && (
                                                                        <IconButton
                                                                            aria-label="Edit"
                                                                            onClick={() => {
                                                                                swdc.changePrefix('new');
                                                                                handleEditClick(row.licenseKey);
                                                                            }}
                                                                        >
                                                                            <Edit className={classes.pencil} />
                                                                        </IconButton>
                                                                    )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                        {addRows.length === 0 && (
                                            <Grid container alignItems="center" style={{ marginTop: '20px' }}>
                                                <Grid item>
                                                    <h4>{locale.t(locale.keys.licensesManagement.title.add)}</h4>
                                                    <h5>{locale.t(locale.keys.licensesManagement.description2)} </h5>
                                                </Grid>
                                                <Grid container justify="center" alignItems="center">
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        className={classes.button}
                                                        onClick={() => {
                                                            swdc.changePrefix('add');
                                                            handleClick();
                                                        }}
                                                        style={{ borderRadius: '50px', padding: '10px 20px' }}
                                                        disabled={isAddButtonDisabled}
                                                    >
                                                        {locale.t(locale.keys.licensesManagement.button.add)}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {addRows.length !== 0 && (
                                            <>
                                                <Grid container alignItems="center" style={{ marginTop: '20px' }}>
                                                    <Grid item>
                                                        <h4>{locale.t(locale.keys.licensesManagement.title.add)}</h4>
                                                    </Grid>
                                                    <Grid item style={{ marginLeft: 'auto', marginRight: '30px' }}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            className={classes.button}
                                                            onClick={() => {
                                                                swdc.changePrefix('add');
                                                                handleClick();
                                                            }}
                                                            style={{ borderRadius: '50px', padding: '10px 20px' }}
                                                            disabled={isAddButtonDisabled}
                                                        >
                                                            {locale.t(locale.keys.licensesManagement.button.add)}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Paper
                                                    className={props.classes.tableRoot}
                                                    style={{ marginTop: '5px', width: '60%' }}
                                                >
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow className={props.classes.headerFont}>
                                                                <TableCell align="left" style={{ width: '50%' }}>
                                                                    <div className={props.classes.gridInsideHeaderCell}>
                                                                        <p
                                                                            className={
                                                                                props.classes.gridInsideHeaderCellText
                                                                            }
                                                                        >
                                                                            {locale.t(
                                                                                locale.keys.licensesManagement.table
                                                                                    .plan,
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell style={{ width: '40%' }}>
                                                                    <div className={props.classes.gridInsideHeaderCell}>
                                                                        <p
                                                                            className={
                                                                                props.classes.gridInsideHeaderCellText
                                                                            }
                                                                        >
                                                                            {locale.t(
                                                                                locale.keys.licensesManagement.table
                                                                                    .usage,
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell style={{ width: '40%' }}>
                                                                    <div className={props.classes.gridInsideHeaderCell}>
                                                                        <p
                                                                            className={
                                                                                props.classes.gridInsideHeaderCellText
                                                                            }
                                                                        >
                                                                            {locale.t(
                                                                                locale.keys.licensesManagement.table
                                                                                    .registerationDate,
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {addRows.map((row, index) => (
                                                                <TableRow
                                                                    key={row.id}
                                                                    className={
                                                                        index % 2
                                                                            ? props.classes.evenRow
                                                                            : props.classes.oddRow
                                                                    }
                                                                >
                                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                                        {row.plan.name}
                                                                    </TableCell>
                                                                    <TableCell>{row.usage}</TableCell>
                                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                                        {dayjs(row.registerationDate).format(
                                                                            'YYYY/MM/DD',
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Paper>
                                            </>
                                        )}
                                    </>
                                )}
                                <LicenseDialogBase
                                    callBack={dialogObject.callBack}
                                    onClose={dialogObject.onClose}
                                    isOpen={dialogObject.isOpen}
                                    title={dialogObject.title}
                                    type={dialogObject.type}
                                    renderChildren={dialogObject.renderChildren}
                                />
                                <LicenseConfirmDialogBase
                                    callBack={confirmDialogObject.callBack}
                                    onClose={confirmDialogObject.onClose}
                                    isOpen={confirmDialogObject.isOpen}
                                    title={confirmDialogObject.title}
                                    type={confirmDialogObject.type}
                                    renderChildren={confirmDialogObject.renderChildren}
                                    disabled={confirmDialogObject.disabled}
                                />
                            </div>
                        )}
                </CardContent>
                {tabIndex !== 2 && (
                    <CardContent>
                        <Grid container spacing={32}>
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Fab
                                    variant="extended"
                                    className={classes.saveButton}
                                    size="large"
                                    color="secondary"
                                    disabled={
                                        swdc.buttonDisabled() ||
                                        (!(
                                            appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS ||
                                            appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS
                                        ) &&
                                            appContainer.values.signinWorkspaceObject.id !==
                                                swdc.values.workspace.parentWorkspace)
                                    }
                                    onClick={() => handleSave()}
                                >
                                    {locale.t(locale.keys.subWorkspaceDetail.settingPanel.save)}
                                </Fab>
                            </Grid>
                        </Grid>
                    </CardContent>
                )}
            </Card>
        </>
    );
};

export default withRouter(withStyles(styles)(Component));
