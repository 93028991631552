import { useState, useRef } from 'react';
import { createContainer } from 'unstated-next';
import { Variants } from '@/common/components/messages/CommonMessage';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as schema from '@/bundles/schema/typescript/schema';
import * as model from '@/common/api/sub-workspace/detail/Edit';
import * as validator from '@/common/utils/validator';
import * as workspace from '@/common/api/workspace/workspace';
import * as modelOrganization from '@/common/models/organization';
import * as license from '@/common/api/licenses/license';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import environment from '@/common/constants/environment';
import locale from '@/common/utils/locale';
import * as errorHandler from '@/common/utils/errorHandler';
import dayjs from 'dayjs';
import * as errLocale from '@/common/utils/locale/error-locale';

export type PlanRow = {
    id: string;
    plan: schema.V1ObjectsLicensePlan;
    total: number;
    remaining: number;
    activationDate: string;
    expirationDate: string;
    status: string;
    isUpdate?: boolean;
    licenseKey?: string;
};

export type AddRow = {
    id: string;
    plan: schema.V1ObjectsLicensePlan;
    usage: number;
    registerationDate: string;
};

const useSubWorkspaceDetailContainer = () => {
    const [values, setValues] = useState(model.New());
    const [organization, setOrganization] = useState(modelOrganization.New());
    const [logoDataUri, setLogoDataUri] = useState('');
    const input1 = useRef('');
    const input2 = useRef('');
    const input3 = useRef('');
    const input4 = useRef('');
    const prefix = useRef('AON');
    const isButtonDisabled = useRef(true);
    const validationMessage1 = useRef('');
    const validationMessage2 = useRef('');
    const validationMessage3 = useRef('');
    const validationMessage4 = useRef('');
    const licenseInfo = useRef<schema.V1LicensesShowResponse | null>(null);

    const ui = useUI();
    const appContainer = AdminAppContainer.useContainer();
    // ui
    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };
    const openMessage = (message: string, variant: Variants) => {
        const isOpen = true;
        appContainer.updateMessage({
            autoHideDuration: 3000,
            isOpen,
            message,
            variant,
        });
    };

    // ワークスペース情報初期化
    const loadWorkspace = async (displayId: string, auth: string) => {
        const result = await workspace.showWorkspace(displayId, auth);
        if (!result || !result.workspace) {
            throw new Error('Workspace not found');
        }
        updateWorkspace(result.workspace);

        const p = await workspace.findWorkspaceUser(values.workspace.id!, auth);
        const childWorkspaceMyself = p.users.find((u) => u.invitationEmail === appContainer.values.signinWorkspaceUserObject.invitationEmail);
        setValues({ ...values, childWorkspaceMyself });
    };

    const updateWorkspace = (w: schema.V1ObjectsWorkspace) => {
        values.logoUrl = w.logoUrl ? w.logoUrl : '';
        values.workspaceId = workspace.isInitialValue(w.displayId) ? '' : w.displayId;
        values.workspaceName = workspace.isInitialValue(w.displayName) ? '' : w.displayName;
        values.workspaceLang = w.language ? w.language : '';
        values.workspaceContactEmail = w.contactEmail ? w.contactEmail : '';
        values.enableSubWorkspace = w.enableSubWorkspace ? w.enableSubWorkspace : false;
        values.workspace = w;
        if (w.organizationDetail) {
            organization.name = w.organizationDetail.name ? w.organizationDetail.name : '';
            organization.zipCode = w.organizationDetail.zipCode ? w.organizationDetail.zipCode : '';
            organization.country = w.organizationDetail.country ? w.organizationDetail.country : '';
            organization.address1 = w.organizationDetail.address1 ? w.organizationDetail.address1 : '';
            organization.address2 = w.organizationDetail.address2 ? w.organizationDetail.address2 : '';
            organization.phoneNumber = w.organizationDetail.phoneNumber ? w.organizationDetail.phoneNumber : '';
            organization.customerId = w.organizationDetail.customerId ? w.organizationDetail.customerId : '';
        }
    };

    // ワークスペースロゴ
    const handleChangeLogoUrl = (v: string) => {
        setValues({ ...values, logoUrl: v });
    };

    const handleChangeLogoDataUri = (v: string) => {
        setLogoDataUri(v);
    };

    // ワークスペースID
    const handleChangeWorkspaceId = (v: string) => {
        setValues({ ...values, workspaceId: v, validateInit: { ...values.validateInit, workspaceId: true } });
    };
    const handleValidateWorkspaceId = () => {
        if (!values.validateInit.workspaceId) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.workspaceId === null ? '' : msg!.workspaceId.toString();
        return dispMessage;
    };

    // ワークスペース表示名
    const handleChangeWorkspaceName = (v: string) => {
        setValues({ ...values, workspaceName: v, validateInit: { ...values.validateInit, workspaceName: true } });
    };
    const handleValidateWorkspaceName = () => {
        if (!values.validateInit.workspaceName) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.workspaceName === null ? '' : msg!.workspaceName.toString();
        return dispMessage;
    };

    // ワークスペース言語
    const handleChangeWorkspaceLang = (v: string) => {
        setValues({ ...values, workspaceLang: v, validateInit: { ...values.validateInit, workspaceLang: true } });
    };
    const handleValidateWorkspaceLang = () => {
        if (!values.validateInit.workspaceLang) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.workspaceLang === null ? '' : msg!.workspaceLang.toString();
        return dispMessage;
    };

    // サブワークスペース作成可能
    const handleChangeEnableSubWorkspace = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, enableSubWorkspace: event.target.checked });
    };

    // 連絡先Eメールアドレス
    const handleChangeContactEmail = (v: string) => {
        setValues({ ...values, workspaceContactEmail: v, validateInit: { ...values.validateInit, workspaceContactEmail: true } });
    };
    const handleValidateContactEmail = () => {
        if (!values.validateInit.workspaceContactEmail) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg.workspaceContactEmail === null ? '' : msg!.workspaceContactEmail.toString();
        return dispMessage;
    };

    // ワークスペース削除
    const handleChangeWorkspaceIdConfirm = (v: string) => {
        setValues({ ...values, workspaceIdConfirm: v });
    };

    const buttonDisabled = () => {
        return (
            validator.Validate<model.Props>(values, model.validations(), model.NewValidation) !== null ||
            validator.Validate<modelOrganization.OrganizationInfo>(organization, modelOrganization.validation(), modelOrganization.NewValidation) !== null
        );
    };

    const removeButtonDisabled = (): boolean => {
        return values.workspace.displayId !== values.workspaceIdConfirm;
    };

    // 表示用に取得したデータを加工
    const convertPlanRows = (licensePlan: schema.V1ObjectsLicenseNew[]): PlanRow[] => {
        const rows: PlanRow[] = [];
        licensePlan.forEach((val) => {
            const totalLicenses = val.plan.volume + (val.addLicenses ? val.addLicenses.reduce((acc, cur) => acc + cur.plan.volume, 0) : 0);
            rows.push({
                id: val.id,
                plan: val.plan,
                total: totalLicenses,
                remaining: val.usage,
                activationDate: val.activationDate,
                expirationDate: val.expirationDate,
                status: val.status,
                isUpdate: val.isUpdate,
                licenseKey: val.licenseKey,
            });
        });

        return rows;
    };

    // 表示用に取得したデータを加工
    const convertAddRows = (addLicensePlan: schema.V1ObjectsLicenseAdd[]): AddRow[] => {
        const rows: AddRow[] = [];
        addLicensePlan.forEach((val) => {
            rows.push({
                id: val.id,
                plan: val.plan,
                usage: val.plan.volume,
                registerationDate: val.updatedAt,
            });
        });

        return rows;
    };

    const changePrefix = async (type: 'new' | 'add') => {
        if (environment.tenant === 'otsuka') {
            prefix.current = type === 'new' ? 'AON' : 'AOA';
        } else if (environment.tenant === 'ricoh') {
            prefix.current = type === 'new' ? 'ARN' : 'ARA';
        }
    };

    /**
     * ライセンスキーの入力値が変更された際の処理
     * ボタンの活性非活性を切り替える
     */
    const changeButtonDisabled = () => {
        const allInputsFilled = input1.current.length === 4 && input2.current.length === 4 && input3.current.length === 4 && input4.current.length === 4;
        if (allInputsFilled) {
            isButtonDisabled.current = false;
        } else {
            isButtonDisabled.current = true;
        }
        return isButtonDisabled.current;
    };

    //入力値と押下判定とバリデーションメッセージの初期化
    const resetInputsAndButtonState = () => {
        input1.current = '';
        input2.current = '';
        input3.current = '';
        input4.current = '';
        isButtonDisabled.current = true;
        validationMessage1.current = '';
        validationMessage2.current = '';
        validationMessage3.current = '';
        validationMessage4.current = '';
    }

    const handleRegisterClick = async () => {
        try {
            ui.update(UI.Loading);
            const request: schema.V1LicensesActivateCreateRequest = {
                workspaceId: values.workspace.id!,
                licenseKey: [`${prefix.current}${input1.current}${input2.current}${input3.current}${input4.current}`],
            };
            await license.licenseActivate(request, appContainer.values.authorizationCode);
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.action.license),
                variant: Variants.success,
                autoHideDuration: 3000,
            });
            ui.update(UI.Loaded);
            resetInputsAndButtonState();
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
            resetInputsAndButtonState();
            return;
        }
    }

    const handleUpdateClick = async (licenseId: string) => {
        try {
            ui.update(UI.Loading);
            const request: schema.V1LicensesReplaceRequest = {
                licenseId: licenseId,
                workspaceId: values.workspace.id!,
            };
            const a = await license.licenseReplace(request, appContainer.values.authorizationCode);
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.action.licenseUpdate),
                variant: Variants.success,
            });
            ui.update(UI.Loaded);
            resetInputsAndButtonState();
            return a;
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
            resetInputsAndButtonState();
            return;
        }
    }

    // ライセンス削除
    const handleDeleteClick = async (licenseId: string) => {
        try {
            ui.update(UI.Loading);
            await license.licenseDelete(licenseId, appContainer.values.authorizationCode);
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.action.licenseDelete),
                variant: Variants.success,
            });
            ui.update(UI.Loaded);
            resetInputsAndButtonState();
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
            resetInputsAndButtonState();
            return;
        }
    }

    const findWorkspaceAllLicenses = async (auth: string) => {
        try {
            const result = await workspace.findWorkspaceLicenses(values.workspace.id!, auth);
            return result;
        } catch (e) {
            ui.update(UI.Loaded);
        }
    };


    // ライセンスキーの情報取得
    // アクティベートは実施されない
    const getLicense = async () => {
        try {
            const licenseKey = `${prefix.current}${input1.current}${input2.current}${input3.current}${input4.current}`;
            const result = await license.getLicenseInfo(licenseKey, appContainer.values.authorizationCode);
            licenseInfo.current = result;
            return true
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            return false;
        }
    }

    // ライセンスキーの情報取得
    // アクティベートは実施されない
    //　ライセンスのステータスが active の場合にエラーメッセージを表示
    const getInacticeLicense = async () => {
        try {
            const licenseKey = `${prefix.current}${input1.current}${input2.current}${input3.current}${input4.current}`;
            const result = await license.getLicenseInfo(licenseKey, appContainer.values.authorizationCode);

            // ライセンスのステータスが active の場合にエラーメッセージを表示
            if (result.licenses && result.licenses.some((license: schema.V1ObjectsLicenseNew) => license.status === 'active')) {
                appContainer.updateMessage({
                    isOpen: true,
                    message: `${errLocale.translate(errLocale.keys.E11006).message}`,
                    variant: Variants.error,
                    resultCode: errLocale.keys.E11006,
                });
                ui.update(UI.Loaded);
                return false;
            }
            licenseInfo.current = result;
            return true
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
            return false;
        }
    }


    // ライセンスキー
    const handleChangeLicenseKey = (v: string, inputRef: React.MutableRefObject<string>, nextInputId: string | null, validationMessageRef: React.MutableRefObject<string>, field: string) => {
        const upperCaseValue = v.toUpperCase();
        inputRef.current = upperCaseValue;
        setValues(prevValues => {
            const newValues = { ...prevValues, licenseKey: upperCaseValue, validateInit: { ...prevValues.validateInit, licenseKey: true } };
            const validationMessage = handleValidateLicenseKey(newValues, field);
            validationMessageRef.current = validationMessage;

            return newValues;
        });
        if (upperCaseValue.length === 4 && nextInputId) {
            const nextInput = document.getElementById(nextInputId) as HTMLInputElement;
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleValidateLicenseKey = (values: any, field: string) => {
        if (!values.validateInit.licenseKey) {
            return '';
        }
        const msg = validator.Validate<model.Props>(values, model.validations(), model.NewValidation);
        const dispMessage = msg === null ? '' : msg[field] === null ? '' : msg![field].toString();
        return dispMessage;
    };

    const sortRows = (rows: PlanRow[]) => {
        const statusPriority: { [key in PlanRow['status']]: number } = {
            'active': 1,
            'inactive': 2,
            'expired': 4,
            'insufficient': 3
        };
        return rows.sort((a, b) => {
            const aPriority = statusPriority[a.status];
            const bPriority = statusPriority[b.status];

            if (aPriority < bPriority) return -1;
            if (aPriority > bPriority) return 1;
            if (aPriority === bPriority) {
                const expirationDiff = dayjs(a.expirationDate).diff(dayjs(b.expirationDate), 'day');
                if (expirationDiff !== 0) {
                    return expirationDiff;
                }
                return dayjs(a.activationDate).diff(dayjs(b.activationDate), 'millisecond');
            }
            return 0;
        });
    };

    const sortRowsByRegisterationDate = (rows: AddRow[]) => {
        return rows.sort((a, b) => {
            return dayjs(a.registerationDate).diff(dayjs(b.registerationDate), 'day');
        });
    };

    return {
        values,
        setValues,
        organization,
        setOrganization,
        // load
        loadWorkspace,
        updateWorkspace,
        // ui
        ui,
        updateUIStatus,
        openMessage,
        // Config
        handleChangeLogoUrl,
        logoDataUri,
        handleChangeLogoDataUri,
        handleChangeWorkspaceId,
        handleValidateWorkspaceId,
        handleChangeWorkspaceName,
        handleValidateWorkspaceName,
        handleChangeWorkspaceLang,
        handleValidateWorkspaceLang,
        handleChangeContactEmail,
        handleValidateContactEmail,
        handleChangeEnableSubWorkspace,
        buttonDisabled,
        findWorkspaceAllLicenses,
        handleRegisterClick,
        input1,
        input2,
        input3,
        input4,
        isButtonDisabled,
        convertPlanRows,
        convertAddRows,
        prefix,
        changePrefix,
        handleChangeLicenseKey,
        handleValidateLicenseKey,
        changeButtonDisabled,
        resetInputsAndButtonState,
        validationMessage1,
        validationMessage2,
        validationMessage3,
        validationMessage4,
        getLicense,
        getInacticeLicense,
        licenseInfo,
        sortRows,
        handleUpdateClick,
        handleDeleteClick,
        sortRowsByRegisterationDate,
        // Remove
        handleChangeWorkspaceIdConfirm,
        removeButtonDisabled,
    };
};
export const SubWorkspaceDetailContainer = createContainer(useSubWorkspaceDetailContainer);
