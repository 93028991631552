import { default as React } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Theme, Button, Card, CardContent, createStyles, withStyles, WithStyles } from '@material-ui/core';

import locale from '@/common/utils/locale';
import logger from '@/common/utils/logger';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { SubWorkspaceDetailContainer } from './SubWorkspaceDetailContainer';
import { State as UI } from '@/common/components/hooks/useUI';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as schema from '@/bundles/schema/typescript/schema';
import * as errorHandler from '@/common/utils/errorHandler';
import * as workspace from '@/common/api/workspace/workspace';
// import * as workspaceSignupModel from '@/admin/models/auth/workspace-signup/WorkspaceSignup';

import * as Invitation from '@/common/api/sub-workspace/inivite/Invitation';
import { OTS_CUSTOMERS, RICOH_CUSTOMERS } from '@/common/constants/environment';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        mb20: {
            marginBottom: 20,
        },
        button: {
            maxWidth: '100%',
            minWidth: '100%',
        },
        hr: {
            margin: '21px 0',
        },
    });
interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const appContainer = AdminAppContainer.useContainer();
    const swdc = SubWorkspaceDetailContainer.useContainer();
    const { classes } = props;

    // 管理者としてサインイン
    // const handleSignin = () => {
    //     logger.debug('handleSignin');
    //     swdc.updateUIStatus(UI.Saving);
    //     (async () => {
    //         try {
    //             // 対象のワークスペースにログイン済の場合、ユーザを切り替えてダッシュボード画面に遷移
    //             await authUtil.selectAlready(swdc.values.workspace.displayId, appContainer);
    //             swdc.updateUIStatus(UI.Loaded);
    //             props.history.push(routes.dashboard.index);
    //         } catch (e) {
    //             logger.debug(e);
    //             swdc.updateUIStatus(UI.Loaded);
    //             // 対象のワークスペースにログインしていない場合、ワークスペース選択済にしてログイン画面へ遷移
    //             props.history.push(`${routes.auth.login.index}${swdc.values.workspaceId}`);
    //         }
    //     })();
    // };

    // 自分を外部管理者にする
    const handleSignupExternalAdmin = () => {
        logger.debug('handleSignupExternalAdmin');
        (async () => {
            try {
                const result = await Invitation.getInviteMyselfURL(
                    {
                        displayId: swdc.values.workspaceId,
                        email: appContainer.values.signinWorkspaceUserObject.invitationEmail,
                    },
                    appContainer.values.authorizationCode,
                );
                logger.debug(result);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.invited),
                    variant: Variants.success,
                });
                window.open(result.url);
            } catch (e) {
                logger.debug(e);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.failedToInvite),
                    variant: Variants.error,
                });
            }
        })();
    };

    // ワークスペースを一時停止
    const handleSuspendWorkspace = () => {
        logger.debug('handleSuspendWorkspace');
        swdc.updateUIStatus(UI.Saving);
        (async () => {
            try {
                if (swdc.values && swdc.values.workspace) {
                    const result = await workspace.suspendWorkspace(swdc.values.workspace.id!, appContainer.values.authorizationCode, swdc.values.workspace);
                    logger.debug('suspendWorkspace result', result);

                    await swdc.loadWorkspace(result.displayId, appContainer.values.authorizationCode);

                    swdc.openMessage(locale.t(locale.keys.action.saved), Variants.success);
                } else {
                    swdc.openMessage(locale.t(locale.keys.error.unknown), Variants.error);
                }
                swdc.updateUIStatus(UI.Loaded);
            } catch (e) {
                swdc.updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    // ワークスペースの一時停止を解除
    const handleResumeWorkspace = () => {
        logger.debug('handleResumeWorkspace');
        swdc.updateUIStatus(UI.Saving);
        (async () => {
            try {
                if (swdc.values && swdc.values.workspace) {
                    const result = await workspace.resumeWorkspace(swdc.values.workspace.id!, appContainer.values.authorizationCode, swdc.values.workspace);
                    logger.debug('resumeWorkspace result', result);

                    await swdc.loadWorkspace(result.displayId, appContainer.values.authorizationCode);

                    swdc.openMessage(locale.t(locale.keys.action.saved), Variants.success);
                } else {
                    swdc.openMessage(locale.t(locale.keys.error.unknown), Variants.error);
                }
                swdc.updateUIStatus(UI.Loaded);
            } catch (e) {
                swdc.updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    // 招待Eメールを送信せずに利用開始
    // サインアップ画面に遷移する
    const handleActivateWorkspace = () => {
        logger.debug('handleActivateWorkspace');
        swdc.updateUIStatus(UI.Saving);
        (async () => {
            try {
                // URLを取得
                const result = await Invitation.getInviteWorkspaceURL(
                    {
                        displayId: swdc.values.workspaceId,
                        email: swdc.values.workspaceContactEmail,
                        withoutEmail: true,
                    },
                    appContainer.values.authorizationCode,
                );
                // サインアップ画面に移動
                logger.debug(result);
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.invited),
                    variant: Variants.success,
                });
                window.open(result.url);
                // verify
                /*
                const verifyReq = workspace.parseWorkspaceInviteVerifyQueryString(result.url);
                const verifyResult = await workspace.workspaceInviteVerify(verifyReq);
                logger.debug('handleActivateWorkspace verify Result', verifyResult);

                if (!verifyResult.success || !verifyResult.user || !verifyResult.workspace || !verifyResult.workspace.id) {
                    swdc.updateUIStatus(UI.Error);
                    return;
                }

                if (verifyResult.user && verifyResult.user.invitationVerified) {
                    // 既に利用開始されています
                    appContainer.updateMessage({
autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.subWorkspaceDetail.signinPanel.instantActivate.failuerUser),
                        variant: Variants.warning,
                    });
                    swdc.updateUIStatus(UI.Loaded);
                    return;
                }

                if (verifyResult.workspaceCreated) {
                    // 既に利用開始されています
                    appContainer.updateMessage({
autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.subWorkspaceDetail.signinPanel.instantActivate.failureWorkspace),
                        variant: Variants.warning,
                    });
                    swdc.updateUIStatus(UI.Loaded);
                    return;
                }
                */

                // Sign Up
                /*
                const sigupForm = workspaceSignupModel.New();
                sigupForm.token = verifyReq.token;
                sigupForm.active = true;
                sigupForm.mail = verifyResult.user.invitationEmail;
                sigupForm.password = 'Mlm8jL#Hh34j';
                sigupForm.name = verifyResult.user.name ? verifyResult.user.name : '';
                sigupForm.phoneticName = verifyResult.user.phoneticName ? verifyResult.user.phoneticName : '';
                sigupForm.role = verifyResult.user.role;
                sigupForm.userLang = verifyResult.user.language ? verifyResult.user.language : schema.Language.En;
                sigupForm.workspaceId = verifyResult.workspace.displayId;
                sigupForm.workspaceName = verifyResult.workspace.displayName;
                sigupForm.workspaceLang = verifyResult.workspace.language ? verifyResult.workspace.language : schema.Language.En;
                const signupReq = workspace.parseWorkspaceSignupRequest(sigupForm);
                const signupResult = await workspace.workspaceSignup(signupReq, false);

                // サインアップ結果の判定
                if (signupResult.workspace && (!signupResult.workspace.active || !signupResult.workspace.verified)) {
                    // 失敗
                    appContainer.updateMessage({
autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.action.error),
                        variant: Variants.error,
                    });
                    swdc.updateUIStatus(UI.Error);
                    return;
                }

                // 利用開始
                swdc.updateWorkspace(signupResult.workspace);
                appContainer.updateMessage({
autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.subWorkspaceDetail.signinPanel.instantActivate.success),
                    variant: Variants.success,
                });
                */
                swdc.updateUIStatus(UI.Loaded);
            } catch (e) {
                swdc.updateUIStatus(UI.Error);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
    };

    return (
        <Card className={classes.card}>
            <CardContent>
                <Button
                    variant="outlined"
                    className={classes.button}
                    size="large"
                    color="secondary"
                    onClick={() => handleSignupExternalAdmin()}
                    disabled={
                        (swdc.values.childWorkspaceMyself && swdc.values.childWorkspaceMyself.invitationVerified) ||
                        !(appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS || appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS) && appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace
                    }
                >
                    {locale.t(locale.keys.subWorkspaceDetail.signinPanel.signupExternalAdmin.index)}
                </Button>
                <hr className={classes.hr} />
                {swdc.values.workspace && !swdc.values.workspace.verified ? (
                    // 「招待Eメールを送信せず利用開始」
                    <Button
                        variant="outlined"
                        className={classes.button}
                        size="large"
                        color="secondary"
                        onClick={() => handleActivateWorkspace()}
                        disabled={!(appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS || appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS) && appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace}
                    >
                        {locale.t(locale.keys.subWorkspaceDetail.signinPanel.instantActivate.index)}
                    </Button>
                ) : swdc.values.workspace && swdc.values.workspace.active ? (
                    // 「ワークスペースを一時停止」
                    <Button
                        variant="outlined"
                        className={classes.button}
                        size="large"
                        color="secondary"
                        onClick={() => handleSuspendWorkspace()}
                        disabled={!(appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS || appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS) && appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace}
                    >
                        {locale.t(locale.keys.subWorkspaceDetail.signinPanel.inactive.index)}
                    </Button>
                ) : (
                    // 「ワークスペースの一時停止を解除する」
                    <Button
                        variant="outlined"
                        className={classes.button}
                        size="large"
                        color="secondary"
                        onClick={() => handleResumeWorkspace()}
                        disabled={!(appContainer.values.signinWorkspaceObject.displayId === OTS_CUSTOMERS || appContainer.values.signinWorkspaceObject.displayId === RICOH_CUSTOMERS) && appContainer.values.signinWorkspaceObject.id !== swdc.values.workspace.parentWorkspace}
                    >
                        {locale.t(locale.keys.subWorkspaceDetail.signinPanel.active.index)}
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};

export default withRouter(withStyles(styles)(Component));
