import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import LicenseConfirmDialogBase, {
    LicenseConfirmDialogBaseProps,
} from '@/common/components/Confirm/LicenseConfirmDialogBase';
import LicenseDialogBase, { LicenseDialogBaseProps } from '@/common/components/Confirm/LicenseDialogBase';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as errorHandler from '@/common/utils/errorHandler';
import locale from '@/common/utils/locale';
import {
    Avatar,
    Button,
    createStyles,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { Block, Check, ErrorOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import dayjs from 'dayjs';
import { default as React, useEffect, useState } from 'react';
import { AddRow, AIocrManagementContainer, PlanRow } from './AIocrManagementContainer';

const styles = (theme: Theme) =>
    createStyles({
        createStyles: {
            marginTop: theme.spacing.unit * 3,
        },
        heading: {
            textAlign: 'left',
            marginLeft: '20px',
        },
        button: {
            color: 'white',
            margin: 'auto',
            minWidth: '40px',
            borderRadius: '50%', // ボタンを丸くする
            padding: '10px', // ボタンの高さを設定
        },
        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },
        oddRow: {
            background: '#f2f4f5',
            minHeight: 60,
        },
        evenRow: {
            background: 'inherit',
            minHeight: 60,
        },
        tableRoot: {
            marginTop: theme.spacing.unit * 5,
            overflowX: 'auto',
        },
        pencil: {
            color: 'orange',
        },
        active: {
            color: 'green',
        },
        inActive: {
            color: 'red',
        },
        standby: {
            color: 'black',
        },
        avatarIcon: {
            borderRadius: 0,
            width: 24,
            height: 24,
            opacity: 0.6,
        },
        visibilityIcon: {
            borderRadius: 0,
            width: 20,
            height: 20,
            opacity: 0.6,
        },
    });

interface Props extends WithStyles<typeof styles> {}

const initialDialogObject: LicenseDialogBaseProps = {
    callBack: () => {},
    onClose: () => {},
    isOpen: false,
    title: '',
    renderChildren: () => <></>,
    type: 'alert',
};

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const appContainer = AdminAppContainer.useContainer();
    const aiocrManagementContainer = AIocrManagementContainer.useContainer();
    const [planRows, setPlanRows] = useState<PlanRow[]>([]);
    const [addRows, setAddRows] = useState<AddRow[]>([]);
    const [totalClient, setTotalClient] = useState(0);
    const [totalServer, setTotalServer] = useState(0);
    const [dialogObject, setDialogObject] = React.useState<LicenseDialogBaseProps>(initialDialogObject);
    const [confirmDialogObject, setConfirmDialogObject] =
        React.useState<LicenseConfirmDialogBaseProps>(initialDialogObject);
    const [showLicenseKey, setShowLicenseKey] = useState<string | null>(null);
    let activeFound = false;

    useEffect(() => {
        appContainer.updateLoadingState(aiocrManagementContainer.ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aiocrManagementContainer.ui]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const licensePlans = await aiocrManagementContainer.findWorkspaceAllLicenses();
                if (licensePlans) {
                    const convertedRows = aiocrManagementContainer.convertPlanRows(licensePlans.licenses);
                    //追加ライセンスを取得
                    const allAddLicenses = licensePlans.licenses
                        .filter((license) => license.status !== 'expired') // expired を除外
                        .flatMap((license) => license.addLicenses || []);
                    const convertedAddRows = aiocrManagementContainer.convertAddRows(allAddLicenses);
                    aiocrManagementContainer.sortRows(convertedRows);
                    setPlanRows(convertedRows);
                    aiocrManagementContainer.sortAddRows(convertedAddRows);
                    setAddRows(convertedAddRows);
                    // 更新前、かつ有効なライセンスのクライアント合計台数を計算
                    const total = convertedRows
                        .filter((row) => row.plan.machineType === 'client' && !row.isUpdate && row.status === 'active')
                        .reduce((sum, row) => sum + (row.plan.machines || 0), 0);
                    setTotalClient(total);
                    // 更新前、かつ有効なライセンスのサーバー合計台数を計算
                    const totalServer = convertedRows
                        .filter((row) => row.plan.machineType === 'server' && !row.isUpdate && row.status === 'active')
                        .reduce((sum, row) => sum + (row.plan.machines || 0), 0);
                    setTotalServer(totalServer);
                }
            } catch (error) {
                errorHandler.handleApiError(appContainer, error);
            }
        };
        fetchData();
    }, [aiocrManagementContainer.refresh]);

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const pasteData = event.clipboardData.getData('text').replace(/[\r\n]/g, '').trim();

        if (pasteData.length <= 4) {
            return;
        }
        event.preventDefault();

        let parts: string[] = [];
        if (pasteData.includes('-') || pasteData.includes('ー')) {
            // ハイフン区切りの場合
            parts = pasteData.split(/[-ー]/).map((part) => part.slice(0, 4));
        } else {
            // ハイフンなしの場合
            const prefixLength = 3;
            const dataWithoutPrefix = pasteData.slice(prefixLength); // プレフィックスを除外
            parts = dataWithoutPrefix.match(/.{1,4}/g) || []; // 4文字ごとに分割
        }

        const target = event.target as HTMLInputElement;
        const targetId = target.id;

        const valuesToPaste = parts.length > 4 ? parts.slice(1, 5) : parts.slice(0, 4);

        const currentValues = [
            aiocrManagementContainer.input1.current || '',
            aiocrManagementContainer.input2.current || '',
            aiocrManagementContainer.input3.current || '',
            aiocrManagementContainer.input4.current || '',
        ];

        // フォーカスされている TextField に応じて値を再配置
        if (valuesToPaste.length > 0) {
            let startIndex = 0;

            if (targetId === 'input1') {
                startIndex = 0;
            } else if (targetId === 'input2') {
                startIndex = 1;
            } else if (targetId === 'input3') {
                startIndex = 2;
            } else if (targetId === 'input4') {
                startIndex = 3;
            }

            // ペーストされた値を現在の値に適用
            const newValues = [...currentValues];
            for (let i = 0; i < valuesToPaste.length && startIndex + i < newValues.length; i++) {
                newValues[startIndex + i] = valuesToPaste[i];
            }

            aiocrManagementContainer.handleChangeLicenseKey(newValues[0], aiocrManagementContainer.input1, 'input2', aiocrManagementContainer.validationMessage1, 'licenseKey');
            aiocrManagementContainer.handleChangeLicenseKey(newValues[1], aiocrManagementContainer.input2, 'input3', aiocrManagementContainer.validationMessage2, 'licenseKey');
            aiocrManagementContainer.handleChangeLicenseKey(newValues[2], aiocrManagementContainer.input3, 'input4', aiocrManagementContainer.validationMessage3, 'licenseKey');
            aiocrManagementContainer.handleChangeLicenseKey(newValues[3], aiocrManagementContainer.input4, null, aiocrManagementContainer.validationMessage4, 'licenseKey');
        }
    };

    // ライセンスキー入力ダイアログ
    const handleClick = () => {
        setDialogObject({
            callBack: () => {
                setDialogObject(initialDialogObject);
            },
            onClose: () => {
                setDialogObject(initialDialogObject);
                aiocrManagementContainer.resetInputsAndButtonState();
            },
            isOpen: true,
            title: locale.t(locale.keys.licensesManagement.register.button),
            renderChildren: () => {
                return (
                    <>
                        <Typography style={{ fontSize: '0.875rem', margin: 10 }}>
                            {locale.t(locale.keys.licensesManagement.register.key)}
                        </Typography>
                        <div style={{ fontSize: '25px' }}>
                            {aiocrManagementContainer.prefix.current} ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input1"
                                    type="text"
                                    value={aiocrManagementContainer.input1.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        aiocrManagementContainer.handleChangeLicenseKey(
                                            event.target.value,
                                            aiocrManagementContainer.input1,
                                            'input2',
                                            aiocrManagementContainer.validationMessage1,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={aiocrManagementContainer.validationMessage1.current !== ''}
                                    helperText={aiocrManagementContainer.validationMessage1.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                            ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input2"
                                    type="text"
                                    value={aiocrManagementContainer.input2.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        aiocrManagementContainer.handleChangeLicenseKey(
                                            event.target.value,
                                            aiocrManagementContainer.input2,
                                            'input3',
                                            aiocrManagementContainer.validationMessage2,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={aiocrManagementContainer.validationMessage2.current !== ''}
                                    helperText={aiocrManagementContainer.validationMessage2.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                            ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input3"
                                    type="text"
                                    value={aiocrManagementContainer.input3.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        aiocrManagementContainer.handleChangeLicenseKey(
                                            event.target.value,
                                            aiocrManagementContainer.input3,
                                            'input4',
                                            aiocrManagementContainer.validationMessage3,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={aiocrManagementContainer.validationMessage3.current !== ''}
                                    helperText={aiocrManagementContainer.validationMessage3.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                            ー
                            <div
                                style={{
                                    border: '1px solid #000',
                                    padding: '5px',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                }}
                            >
                                <TextField
                                    id="input4"
                                    type="text"
                                    value={aiocrManagementContainer.input4.current}
                                    inputProps={{ maxLength: 4 }}
                                    InputProps={{ disableUnderline: true }}
                                    style={{
                                        width: '70px',
                                        height: '40px',
                                        fontSize: '20px',
                                        marginLeft: '5px',
                                        verticalAlign: 'middle',
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        event.target.value = event.target.value
                                            .replace(/[^\x00-\x7F]/g, '')
                                            .toUpperCase();
                                        aiocrManagementContainer.handleChangeLicenseKey(
                                            event.target.value,
                                            aiocrManagementContainer.input4,
                                            null,
                                            aiocrManagementContainer.validationMessage4,
                                            'licenseKey',
                                        );
                                    }}
                                    onPaste={handlePaste}
                                    error={aiocrManagementContainer.validationMessage4.current !== ''}
                                    helperText={aiocrManagementContainer.validationMessage4.current}
                                    FormHelperTextProps={{
                                        style: { fontSize: '12px' },
                                    }}
                                />
                            </div>
                        </div>
                        <Typography style={{ fontSize: '0.75rem', margin: 30, marginTop: 40 }}>
                            {locale.t(locale.keys.licensesManagement.register.description2)}
                        </Typography>
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={async () => {
                                    // 事前にライセンスキーの確認
                                    const result = await aiocrManagementContainer.getInacticeLicense();
                                    if (result === true) {
                                        handleConfirmClick(
                                            `${aiocrManagementContainer.prefix.current} ー ${aiocrManagementContainer.input1.current} ー ${aiocrManagementContainer.input2.current} ー ${aiocrManagementContainer.input3.current} ー ${aiocrManagementContainer.input4.current}`,
                                        );
                                        setDialogObject(initialDialogObject);
                                    }
                                }}
                                style={{ borderRadius: '50px', padding: '10px 20px' }}
                                disabled={aiocrManagementContainer.changeButtonDisabled()}
                            >
                                <div style={{ color: 'white' }}>
                                    {locale.t(locale.keys.licensesManagement.register.button)}
                                </div>
                            </Button>
                        </div>
                    </>
                );
            },
            type: 'confirm',
        });
    };

    // ライセンス登録の確認ダイアログ
    const handleConfirmClick = async (licenseKey: string) => {
        const expirationDate = dayjs()
            .add(10, 'day')
            .startOf('month')
            .add(1, 'month')
            .startOf('month')
            .add(1, 'year')
            .subtract(1, 'day')
            .format('YYYY/MM/DD');
        setConfirmDialogObject({
            callBack: () => {
                setConfirmDialogObject(initialDialogObject);
                aiocrManagementContainer.handleRegisterClick();
            },
            onClose: () => {
                setConfirmDialogObject(initialDialogObject);
                handleClick();
                aiocrManagementContainer.resetInputsAndButtonState();
            },
            isOpen: true,
            title: locale.t(locale.keys.licensesManagement.register.button),
            renderChildren: () => {
                return (
                    <>
                        <Typography style={{ fontSize: '0.875rem', margin: 10 }}>
                            {locale.t(locale.keys.licensesManagement.register.key)}
                        </Typography>
                        <Typography style={{ fontSize: '25px', margin: 10 }}>{licenseKey}</Typography>
                        <Typography style={{ fontSize: '20px', margin: 30 }}>
                            {locale.t(locale.keys.licensesManagement.register.confirm.description1)}
                        </Typography>
                        <Typography style={{ fontSize: '15px', margin: 10 }}>
                            {`${locale.t(locale.keys.licensesManagement.register.confirm.plan)}: ${aiocrManagementContainer.licenseInfo.current ? aiocrManagementContainer.licenseInfo.current.licenses[0].plan.name : ''}`}
                        </Typography>
                        <Typography style={{ fontSize: '15px', margin: 10 }}>
                            {`${locale.t(locale.keys.licensesManagement.register.confirm.expirationDate)}: ${expirationDate}`}
                        </Typography>
                        <Typography style={{ fontSize: '15px', margin: 10 }}>
                            {`${locale.t(locale.keys.licensesManagement.register.confirm.usage)}: ${aiocrManagementContainer.licenseInfo && aiocrManagementContainer.licenseInfo.current ? aiocrManagementContainer.licenseInfo.current.licenses[0].plan.volume : ''}${locale.t(locale.keys.licensesManagement.register.confirm.sheets)}`}
                        </Typography>
                    </>
                );
            },
            type: 'confirm',
        });
    };

    const handleCopy = (text: string) => {
        const formattedText = formatLicenseKey(text);
        navigator.clipboard.writeText(formattedText).then(() => {
            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.orderOCRLicenseDetail.button.description1),
                variant: Variants.success,
            });
        });
    };

    const formatLicenseKey = (key: string) => {
        return key.replace(/(.{3})(.{4})(.{4})(.{4})(.{4})/, '$1-$2-$3-$4-$5');
    };

    const renderMaskedLicenseKey = () => {
        return <Typography>{'****-****-****-****-****'}</Typography>;
    };

    const renderFormattedLicenseKey = (licenseKey: string) => {
        return <Typography>{formatLicenseKey(licenseKey)}</Typography>;
    };

    const handleLicenseDialogOpen = (licenseKey: string) => {
        setDialogObject({
            callBack: () => setDialogObject(initialDialogObject),
            onClose: () => {
                setDialogObject(initialDialogObject);
                setShowLicenseKey(null);
            },
            isOpen: true,
            title: locale.t(locale.keys.aiOcrManagement.table.licenseKey),
            type: 'alert',
            renderChildren: () => (
                <>
                    <div
                        style={{
                            textAlign: 'center',
                            margin: '5px 10px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <div style={{ marginTop: '20px', marginRight: '20px' }}>
                            {
                                showLicenseKey === licenseKey
                                    ? renderFormattedLicenseKey(licenseKey) // フォーマット済みのライセンスキーを表示
                                    : renderMaskedLicenseKey() // マスクされたライセンスキーを表示
                            }
                        </div>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={() => {
                                setDialogObject((prevDialogObject) => ({
                                    ...prevDialogObject,
                                    renderChildren: () => (
                                        <>
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    margin: '10px 0',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px',
                                                }}
                                            >
                                                <Typography>{formatLicenseKey(licenseKey)}</Typography>
                                                <Button
                                                    variant="contained"
                                                    className={classes.button}
                                                    onClick={() => {
                                                        handleLicenseDialogOpen(licenseKey);
                                                    }}
                                                    style={{
                                                        borderRadius: '40px',
                                                        backgroundColor: 'transparent',
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <VisibilityOff style={{ color: 'gray' }} />
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className={classes.button}
                                                    onClick={() => {
                                                        handleCopy(licenseKey);
                                                    }}
                                                    style={{ borderRadius: '50px', backgroundColor: 'transparent' }}
                                                >
                                                    <Avatar
                                                        className={classes.visibilityIcon}
                                                        src="/assets/images/logo/copy.svg"
                                                    />
                                                </Button>
                                            </div>
                                        </>
                                    ),
                                }));
                            }}
                            style={{ borderRadius: '40px', backgroundColor: 'transparent', marginTop: '10px' }}
                        >
                            <Visibility style={{ color: 'gray' }} />
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={() => {
                                handleCopy(licenseKey);
                            }}
                            style={{ borderRadius: '50px', backgroundColor: 'transparent', marginTop: '10px' }}
                        >
                            <Avatar className={classes.visibilityIcon} src="/assets/images/logo/copy.svg" />
                        </Button>
                    </div>
                </>
            ),
        });
    };

    return (
        <div>
            {planRows.length == 0 && (
                <>
                    <h4 className={classes.heading}>{locale.t(locale.keys.aiOcrManagement.initialDesc)}</h4>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                                aiocrManagementContainer.changePrefix();
                                handleClick();
                            }}
                            style={{ borderRadius: '50px', padding: '10px 20px' }}
                        >
                            {locale.t(locale.keys.licensesManagement.initial.button)}
                        </Button>
                    </div>
                </>
            )}
            {planRows.length != 0 && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4 className={classes.heading}>{locale.t(locale.keys.aiOcrManagement.desc)}</h4>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                                aiocrManagementContainer.changePrefix();
                                handleClick();
                            }}
                            style={{ borderRadius: '50px', padding: '10px 20px', marginLeft: '400px' }}
                        >
                            {locale.t(locale.keys.licensesManagement.button.new)}
                        </Button>
                    </div>
                    <Paper className={props.classes.tableRoot} style={{ marginTop: '5px', width: '1020px' }}>
                        <Table>
                            <TableHead>
                                <TableRow className={props.classes.headerFont}>
                                    <TableCell align="left" style={{ width: '20%' }}>
                                        <div>
                                            <p>{locale.t(locale.keys.aiOcrManagement.table.plan)}</p>
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ width: '15%', whiteSpace: 'nowrap' }}>
                                        <div className={props.classes.gridInsideHeaderCell}>
                                            <p className={props.classes.gridInsideHeaderCellText}>
                                                {locale.t(locale.keys.aiOcrManagement.table.total)}
                                            </p>
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ width: '15%', wordBreak: 'keep-all' }}>
                                        <div className={props.classes.gridInsideHeaderCell}>
                                            <p className={props.classes.gridInsideHeaderCellText}>
                                                {locale.t(locale.keys.aiOcrManagement.table.remaining)}
                                            </p>
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ width: '15%' }}>
                                        <div className={props.classes.gridInsideHeaderCell}>
                                            <p className={props.classes.gridInsideHeaderCellText}>
                                                {locale.t(locale.keys.aiOcrManagement.table.startDate)}
                                            </p>
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ width: '15%' }}>
                                        <div className={props.classes.gridInsideHeaderCell}>
                                            <p className={props.classes.gridInsideHeaderCellText}>
                                                {locale.t(locale.keys.aiOcrManagement.table.expirationDate)}
                                            </p>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        {locale.t(locale.keys.aiOcrManagement.table.status.index)}
                                    </TableCell>
                                    <TableCell align="center" style={{ wordBreak: 'keep-all' }}>
                                        {locale.t(locale.keys.aiOcrManagement.table.licenseKey)}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {planRows.map((row, index) => (
                                    <TableRow
                                        key={row.id}
                                        className={index % 2 ? props.classes.evenRow : props.classes.oddRow}
                                    >
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>{row.plan.name}</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>{row.total}</TableCell>
                                        <TableCell
                                            style={{
                                                color:
                                                    row.remaining === 0
                                                        ? 'red'
                                                        : row.remaining < 100
                                                          ? 'orange'
                                                          : 'inherit',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {row.remaining}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                            {dayjs(row.activationDate).format('YYYY/MM/DD')}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                whiteSpace: 'nowrap',
                                                color:
                                                    dayjs(row.expirationDate)
                                                        .subtract(1, 'day')
                                                        .startOf('day')
                                                        .diff(dayjs().startOf('day'), 'day') < 0
                                                        ? 'red'
                                                        : dayjs(row.expirationDate)
                                                                .subtract(1, 'day')
                                                                .startOf('day')
                                                                .diff(dayjs().startOf('day'), 'day') < 30
                                                          ? 'orange'
                                                          : 'inherit',
                                            }}
                                        >
                                            {dayjs(row.expirationDate).subtract(1, 'day').format('YYYY/MM/DD')}
                                        </TableCell>
                                        {row.status === 'expired' ? (
                                            <TableCell
                                                className={classes.inActive}
                                                align="center"
                                                style={{ whiteSpace: 'nowrap', padding: '0 16px' }}
                                            >
                                                <Block style={{ marginBottom: -5 }} />
                                                {locale.t(locale.keys.aiOcrManagement.table.status.expired)}
                                            </TableCell>
                                        ) : row.status === 'insufficient' ? (
                                            <TableCell
                                                className={classes.inActive}
                                                align="center"
                                                style={{ whiteSpace: 'nowrap', padding: '0 16px' }}
                                            >
                                                <Block style={{ marginBottom: -5 }} />
                                                {locale.t(locale.keys.aiOcrManagement.table.status.soldOut)}
                                            </TableCell>
                                        ) : row.status === 'active' && !activeFound ? (
                                            ((activeFound = true),
                                            (
                                                <TableCell
                                                    className={classes.active}
                                                    align="center"
                                                    style={{ whiteSpace: 'nowrap', padding: '0 16px' }}
                                                >
                                                    <Check style={{ marginBottom: -5 }} />
                                                    {locale.t(locale.keys.aiOcrManagement.table.status.active)}
                                                </TableCell>
                                            ))
                                        ) : (
                                            <TableCell
                                                className={classes.standby}
                                                align="center"
                                                style={{ whiteSpace: 'nowrap', padding: '0 16px' }}
                                            >
                                                {locale.t(locale.keys.aiOcrManagement.table.status.standby)}
                                            </TableCell>
                                        )}
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                            <IconButton
                                                onClick={() =>
                                                    row.licenseKey && handleLicenseDialogOpen(row.licenseKey)
                                                }
                                            >
                                                <ErrorOutline color="secondary" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                    <h5 className={classes.heading} style={{ marginTop: '20px' }}>
                        {totalClient !== 0 && totalServer !== 0 ? (
                            <>
                                {locale.t(locale.keys.aiOcrManagement.machines.client2, { n: totalClient })}
                                {locale.t(locale.keys.aiOcrManagement.machines.server, { n: totalServer })}
                                {locale.t(locale.keys.aiOcrManagement.machines.desc)}
                            </>
                        ) : totalClient !== 0 ? (
                            <>
                                {locale.t(locale.keys.aiOcrManagement.machines.client1, { n: totalClient })}
                                {locale.t(locale.keys.aiOcrManagement.machines.desc)}
                            </>
                        ) : totalServer !== 0 ? (
                            <>
                                {locale.t(locale.keys.aiOcrManagement.machines.server, { n: totalServer })}
                                {locale.t(locale.keys.aiOcrManagement.machines.desc)}
                            </>
                        ) : null}
                    </h5>
                    {addRows.length != 0 && (
                        <>
                            <h4 className={classes.heading} style={{ marginTop: '20px' }}>
                                {locale.t(locale.keys.aiOcrManagement.add)}
                            </h4>
                            <Paper className={props.classes.tableRoot} style={{ marginTop: '5px', width: '400px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={props.classes.headerFont}>
                                            <TableCell align="left">
                                                <div>
                                                    <p>{locale.t(locale.keys.aiOcrManagement.table.plan)}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    className={props.classes.gridInsideHeaderCell}
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    <p className={props.classes.gridInsideHeaderCellText}>
                                                        {locale.t(locale.keys.aiOcrManagement.table.usage)}
                                                    </p>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className={props.classes.gridInsideHeaderCell}>
                                                    <p className={props.classes.gridInsideHeaderCellText}>
                                                        {locale.t(locale.keys.aiOcrManagement.table.registerationDate)}
                                                    </p>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {addRows.map((row, index) => (
                                            <TableRow
                                                key={row.id}
                                                className={index % 2 ? props.classes.evenRow : props.classes.oddRow}
                                            >
                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row.plan.name}</TableCell>
                                                <TableCell align="center"> {row.usage}</TableCell>
                                                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                                                    {dayjs(row.registerationDate).format('YYYY/MM/DD')}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </>
                    )}
                </>
            )}
            <LicenseDialogBase
                callBack={dialogObject.callBack}
                onClose={dialogObject.onClose}
                isOpen={dialogObject.isOpen}
                title={dialogObject.title}
                type={dialogObject.type}
                renderChildren={dialogObject.renderChildren}
            />
            <LicenseConfirmDialogBase
                callBack={confirmDialogObject.callBack}
                onClose={confirmDialogObject.onClose}
                isOpen={confirmDialogObject.isOpen}
                title={confirmDialogObject.title}
                type={confirmDialogObject.type}
                renderChildren={confirmDialogObject.renderChildren}
                disabled={confirmDialogObject.disabled}
            />
        </div>
    );
};
export default withStyles(styles)(Component);
