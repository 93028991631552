import { useState, default as React, FC } from 'react';
import { Theme, createStyles, withStyles, WithStyles, TableBody, TableRow, TableCell, TableHead, Paper, Table, TablePagination, IconButton } from '@material-ui/core';
import locale from '@/common/utils/locale';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';
import { ErrorOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';

const styles = (theme: Theme) => createStyles({
    root: {
        width: 800,
        overflowX: 'auto',
        marginTop: theme.spacing.unit * 5,
        marginLeft: '30px',
    },
    table: {
        // minWidth: 700,
        // maxWidth: 1000, // Set a maximum width for the table
        width: '100%',
        padding: 0,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        paddingInline: '4px',
        paddingTop: 0,
        paddingBottom: 0,
        height: 30,
        fontSize: '16px'
    },
    oddRow: {
        background: '#f2f4f5',
    },
    evenRow: {
        background: 'inherit',
    },
});

const headerStyles = (theme: Theme) => createStyles({
    avatarRow: {
        width: '25%',
        padding: '10px', // Add padding around the text
    },
    headerFont: {
        '&& th': {
            fontSize: 15,
            fontWeight: 'bold',
            color: '#333333',
            padding: '8px', // Add padding around the text
        },
        '&& td': {
            fontSize: 12,
            color: '#333333',
            fontWeight: 'bold',
            padding: '8px', // Add padding around the text
        },
    },
});

interface Props extends WithStyles<typeof styles> {
    licenseRow: Row[];
}

interface HeaderProps extends WithStyles<typeof headerStyles> {}

export interface Row {
    planName: string;
    amount: number;
    createdAt: string;
    createRequestId: string;
}

export const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (
        <>
            {props.licenseRow ? (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <StyleHeader />
                    <TableBody>
                    {props.licenseRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                        <TableRow key={index} className={index % 2 === 0 ? classes.evenRow : classes.oddRow}>
                            <TableCell className={classes.tableCell} align='left' style={{ borderBottom: 'none', width: '45%'}}>
                                {row.planName}
                            </TableCell>
                            <TableCell className={classes.tableCell} align='center' style={{ borderBottom: 'none', width: '20%' }}>
                                {row.amount}
                            </TableCell>
                            <TableCell className={classes.tableCell} align='center' style={{ borderBottom: 'none', width: '25%' }}>
                                {row.createdAt}
                            </TableCell>
                            <TableCell className={classes.tableCell} align='center' style={{ borderBottom: 'none', width: '10%' }}>
                                <Link to={`/license/order/detail/${row.createRequestId}`}>
                                    <IconButton>
                                        <ErrorOutline color='secondary' />
                                    </IconButton>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                    labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                        <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                    )}
                    backIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.previousPage),
                    }}
                    nextIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.nextPage),
                    }}
                    count={props.licenseRow.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={(event, newPage) => setPage(newPage)}
                />
                </div>
            </Paper>
            ) : (
            ''
            )}
        </>
    );
}
const Header: FC<HeaderProps> = (props) => {
    return (
        <TableHead>
            <TableRow className={props.classes.headerFont}>
                <TableCell align='center' className={props.classes.avatarRow} style={{width: '45%'}}>{locale.t(locale.keys.orderOCRLicense.table.header.plan)}</TableCell>
                <TableCell align='center' className={props.classes.avatarRow}style={{width: '20%'}}>{locale.t(locale.keys.orderOCRLicense.table.header.count)}</TableCell>
                <TableCell align='center' className={props.classes.avatarRow}style={{width: '25%'}}>{locale.t(locale.keys.orderOCRLicense.table.header.date)}</TableCell>
                <TableCell align='center' className={props.classes.avatarRow}style={{width: '10%'}}>{locale.t(locale.keys.orderOCRLicense.table.header.detail)}</TableCell>
            </TableRow>
        </TableHead>
    );
}

const StyleHeader = withStyles(headerStyles)(Header);

export default withStyles(styles, { withTheme: true })(Component);